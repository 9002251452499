import React from 'react'

import { ReactComponent as Plumber } from 'Assets/Icons/plumber.svg'
import { ReactComponent as UP } from 'Assets/Tokens/UP.svg'
import { ReactComponent as UL } from 'Assets/Tokens/UL.svg'

import './Icon.scss'

export const IconLib: { [iconName: string]: React.FC } = {
  Plumber,
  UP: () => <UP />,
  UPtrx: () => <UP />,
  UPont: () => <UP />,
  UPone: () => <UP />,
  UPbnb: () => <UP />,
  UPicx: () => <UP />,
  UPeth: () => <UP />,
  UL: () => <UL />
}

export const Icon: React.FC<{ icon: string }> = ({ icon }) => {
  if (IconLib[icon]) {
    const IconComp = IconLib[icon]
    return <IconComp />
  }
  return (
    <img
      src={`https://assets.unifiprotocol.com/${icon.toUpperCase()}.png`}
      alt={icon}
    />
  )
}
