import { useCallback } from 'react'
import { ContractMethod } from '../../Adapters/Contract'
import {
  ExecutionResponse,
  NON_SUCCESS_RESPONSE
} from '../../Adapters/IAdapter'
import { Config } from '../../Config'
import { BigNumber, BN, isNaN, toHex } from '../../Utils/BigNumber'
import { getPrecision } from '../../Utils/Decimals'
import { useAdapter } from './useAdapter'

const SEED: { address: string; decimals: number } = (Config.contracts as any)
  .SEED

export const useSeedMigrate = () => {
  const { execute, adapter } = useAdapter()

  const migrate = useCallback(
    async (amount: string | number, userMigrateAddress: string) => {
      const migrateAddress = Config.depositSeedContract
      if (adapter && migrateAddress) {
        const num = toHex(
          Math.trunc(
            BigNumber(amount)
              .multipliedBy(getPrecision(SEED.address))
              .toNumber()
          )
        )

        const approveResponse = await execute(
          SEED.address,
          ContractMethod.APPROVE,
          { args: [migrateAddress, num] },
          true
        )

        if (!approveResponse.success) {
          return { ...NON_SUCCESS_RESPONSE } as ExecutionResponse
        }

        const migrateResponse = await execute(
          migrateAddress,
          ContractMethod.DEPOSIT_SEED_TOKEN,
          { args: [num, userMigrateAddress] },
          true
        )

        return migrateResponse
      }
      return { ...NON_SUCCESS_RESPONSE } as ExecutionResponse
    },
    [adapter, execute]
  )

  const getCurrentDeposit = useCallback(async () => {
    const migrateAddress = Config.depositSeedContract
    if (adapter && migrateAddress) {
      const bnbAddressOf = await execute(
        migrateAddress,
        ContractMethod.BNB_ADDRESS_OF,
        { args: [adapter.getAddress()] }
      )
      const seedDepositedOf = await execute(
        migrateAddress,
        ContractMethod.SEED_DEPOSITED_OF,
        { args: [adapter.getAddress()] }
      )
      return {
        seed: isNaN(seedDepositedOf.value)
          ? '0'
          : BN(seedDepositedOf.value).div(getPrecision(SEED.address)).toFixed(),
        address: bnbAddressOf.value
      }
    }
    return { seed: '0', address: '' }
  }, [adapter, execute])

  const getClaimScore = useCallback(async () => {
    const claimAddress = Config.claimUP
    if (adapter && claimAddress) {
      const scoreOf = await execute(claimAddress, ContractMethod.SCORE_OF, {
        args: [adapter.getAddress()]
      })
      return BN(scoreOf.value).toFixed()
    }
    return '0'
  }, [adapter, execute])

  const getScoring = useCallback(async () => {
    const claimAddress = Config.claimUP
    if (adapter && claimAddress) {
      const scoring = await execute(claimAddress, ContractMethod.SCORING, {
        args: [adapter.getAddress()]
      })
      return BN(scoring.value)
        .div(getPrecision((Config.contracts as any).SEED.address))
        .dp(6)
        .toFixed()
    }
    return '0'
  }, [adapter, execute])

  const claimMigrationUP = useCallback(async () => {
    const claimAddress = Config.claimUP
    if (adapter && claimAddress) {
      const claimUP = await execute(
        claimAddress,
        ContractMethod.CLAIM_UP,
        {},
        true
      )
      return claimUP
    }
    return { ...NON_SUCCESS_RESPONSE } as ExecutionResponse
  }, [adapter, execute])

  return {
    migrate,
    getCurrentDeposit,
    getClaimScore,
    getScoring,
    claimMigrationUP
  }
}
