import React from 'react'
import { ClaimSquare } from './ClaimSquare'

import './ClaimMigrationUP.scss'

export const ClaimMigrationUP = () => {
  return (
    <div className="ClaimMigrationUP">
      <div className="ClaimMigrationUP__header">
        <img src={'https://assets.unifiprotocol.com/UP.png'} alt="Claim UP" />
        <span>
          <h1>Claim UP</h1>
          <p></p>
        </span>
      </div>
      <div className="ClaimMigrationUP__content">
        <ClaimSquare />
      </div>
    </div>
  )
}
