import { Config } from 'Config'
import { Contract, Farm, Pair, Pool } from 'Stores/Pools/Types'
import { fetchJSON } from 'Utils/Fetch'

const isAlpha = /(alpha|localhost)/.test(window.location.href)
const BASE_ENDPOINT = 'https://assets.unifiprotocol.com/'

export const getPoolsRoute = (type: 'pairs' | 'pools' | 'farms') => {
  return isAlpha
    ? `${BASE_ENDPOINT}${type}-alpha-${Config.blockchain}.json`
    : `${BASE_ENDPOINT}${type}-${Config.blockchain}.json`
}

export const useAPI = () => {
  const getPoolsData = async (): Promise<Pool[]> => {
    const pools = await fetchJSON<Pool[]>(getPoolsRoute('pools'), {})
    return pools
  }

  const getPairsData = async (): Promise<{
    pairs: Pair[]
    contracts: { [token: string]: Contract }
  }> => {
    const pairsResponse = await fetchJSON<{
      pairs: Pair[]
      contracts: { [token: string]: Contract }
    }>(getPoolsRoute('pairs'), {})
    return pairsResponse
  }

  const getFarmsData = async (): Promise<Farm[]> => {
    const farmsResponse = await fetchJSON<Farm[]>(getPoolsRoute('farms'), {})
    return farmsResponse
  }

  return {
    getFarmsData,
    getPoolsData,
    getPairsData
  }
}
