import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Input from 'Components/Input'
import SwapDetail from 'Components/SwapDetail'
import { useSwap } from 'Stores/Swap/useSwap'
import { Config } from 'Config'
import { useBalances } from 'Stores/Balances/useBalances'
import { truncateDecimals } from 'Utils/BigNumber'
import { SwapHoriz } from '@material-ui/icons'
import { TokenSelector } from 'Components/TokenSelector'
import { getDecimals } from 'Utils/Decimals'
import { useTranslation } from 'react-i18next'
import { usePools } from '../../Stores/Pools/usePools'
import { PerformSwapButton } from '../../Components/PerformSwapButton'

import './Swap.scss'

export const Swap: React.FC = () => {
  const { t } = useTranslation()
  const { getBalanceByToken } = useBalances()
  const history = useHistory()
  const {
    updateSwap,
    performSwap,
    onInputChange,
    maxTransaction,
    tokenA,
    tokenB,
    amountTokenB,
    amountTokenA,
    selectedPair,
    tradeFee,
    price,
    slippage,
    isSwapReady,
    toAvailablePairs,
    pairs,
    flow,
    againstPrice,
    submitMessage,
    lastSideInput
  } = useSwap()
  const { contracts } = usePools()

  const tokenASelected = useMemo(() => {
    return pairs.find((p) => p.value === tokenA)
  }, [tokenA, pairs])

  const tokenBSelected = useMemo(() => {
    return pairs.find((p) => p.value === tokenB)
  }, [tokenB, pairs])

  const onSubmitHandler = useCallback(async () => {
    try {
      const swapResponse = await performSwap()
      if (swapResponse && swapResponse.success) {
        history.push(`/trade/success/${swapResponse.hash}`)
      } else {
        history.push('/failed')
      }
    } catch (err) {
      history.push('/failed')
    }
  }, [performSwap, history])

  const tokenABalances = useMemo(() => {
    const detail = contracts[tokenA as keyof typeof Config.contracts]
    if (detail) {
      return getBalanceByToken(detail.address)
    }
    return '0'
  }, [tokenA, getBalanceByToken, contracts])

  const tokenBBalances = useMemo(() => {
    const detail = contracts[tokenB as keyof typeof Config.contracts]
    if (detail) {
      return getBalanceByToken(detail.address)
    }
    return '0'
  }, [tokenB, getBalanceByToken, contracts])

  const truncatedTokenA = useMemo(() => {
    return amountTokenA
      ? truncateDecimals(amountTokenA, getDecimals(tokenA))
      : ''
  }, [amountTokenA, tokenA])

  const truncatedTokenB = useMemo(() => {
    return amountTokenB
      ? truncateDecimals(amountTokenB, getDecimals(tokenB))
      : ''
  }, [amountTokenB, tokenB])

  const onClickSwap = useCallback(() => {
    updateSwap({
      tokenA: tokenB,
      tokenB: tokenA,
      amountTokenA: amountTokenB,
      amountTokenB: amountTokenA
    })
  }, [updateSwap, amountTokenA, amountTokenB, tokenA, tokenB])

  useEffect(() => {
    if (toAvailablePairs.length === 1 && tokenA !== Config.blockchainToken) {
      updateSwap({ tokenB: toAvailablePairs[0].value })
    }
    // eslint-disable-next-line
  }, [toAvailablePairs])

  return (
    <div className="Swap">
      <span className="Swap__actions">
        <span className="Swap__actions__action">
          <div className="Swap__actions__action__orientation">
            <h1>{t('trade.from')}</h1>
            <TokenSelector
              selected={tokenASelected}
              options={pairs}
              onChange={(v) => {
                updateSwap({ tokenA: v.value })
              }}
            />
          </div>
          <div>
            <Input
              placeholder={t('trade.enter-amount')}
              onChange={onInputChange('TokenA')}
              value={truncatedTokenA}
              balance={tokenABalances}
              estimated={lastSideInput !== 'TokenA'}
              max
            />
          </div>
        </span>
        <span className="Swap__actions__separator" onClick={onClickSwap}>
          <SwapHoriz />
        </span>
        <span className="Swap__actions__action">
          <div className="Swap__actions__action__orientation">
            <h1>{t('trade.to')}</h1>
            <TokenSelector
              selected={tokenBSelected}
              options={toAvailablePairs}
              onChange={(v) => {
                updateSwap({ tokenB: v.value })
              }}
            />
          </div>
          <div>
            <Input
              placeholder={t('trade.enter-amount')}
              onChange={onInputChange('TokenB')}
              value={truncatedTokenB}
              balance={tokenBBalances}
              estimated={lastSideInput === 'TokenA'}
              max
            />
          </div>
        </span>
      </span>
      <SwapDetail
        tokenA={tokenA}
        tokenB={tokenB}
        maxTransaction={maxTransaction}
        selectedPair={selectedPair}
        tradeFee={tradeFee}
        price={price}
        slippage={slippage}
        amountTokenB={amountTokenB}
        againstPrice={againstPrice}
      />

      <PerformSwapButton
        amountTokenA={amountTokenA}
        flow={flow}
        pair={selectedPair}
        onClick={onSubmitHandler}
        swapReady={isSwapReady}
        submitMessage={submitMessage}
      />
    </div>
  )
}
