import React from 'react'
import UnbrandedUnifi from 'Assets/unifi-logo-unbranded.png'

import './NoConnected.scss'
import { useTranslation } from 'react-i18next'

export const NoConnected = () => {
  const { t } = useTranslation()

  return (
    <div className="NoConnected">
      <h1>{t('locked-section.unlock-your-wallet')}</h1>
      <span>
        <img src={UnbrandedUnifi} alt="Unifi" />
      </span>
    </div>
  )
}
