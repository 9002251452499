import React, { useCallback, useMemo, useRef } from 'react'
import { useDetectOutsideClick } from '../../Utils/useDetectOutsideClick'
import { SwapVert } from '@material-ui/icons'
import { useAdapter } from '../../Stores/Adapter/useAdapter'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { TrackedTransactions } from '../../Stores/Transactions/TrackedTransactions'
import { Config } from '../../Config'
import { useTrackedTransactions } from '../../Stores/Transactions/useTrackedTransactions'

import './TransactionsDropdown.scss'

export const TransactionsDropdown = () => {
  const dropdownRef = useRef(null)
  const { isActive, setIsActive } = useDetectOutsideClick(dropdownRef, false)
  const { adapter, isConnected } = useAdapter()
  const { t } = useTranslation()
  const trackedTransactions = useRecoilValue(TrackedTransactions)
  const { markAllAsNotified } = useTrackedTransactions()

  const onClick = useCallback(() => {
    if (!isActive) {
      markAllAsNotified()
    }
    setIsActive(!isActive)
  }, [setIsActive, markAllAsNotified, isActive])

  const hasNotifications = useMemo(
    () => trackedTransactions.some((t) => t.notified === false),
    [trackedTransactions]
  )

  if (!isConnected || !adapter?.waitForTransaction) {
    return null
  }

  return (
    <div className="TransactionsDropdown">
      <span onClick={onClick} className="TransactionsDropdown__trigger">
        <SwapVert />
        {t('top-header.transactions')}
        {hasNotifications && (
          <span className="TransactionsDropdown__trigger__notification"></span>
        )}
      </span>
      <nav
        ref={dropdownRef}
        className={`TransactionsDropdown__menu ${
          isActive ? 'active' : 'inactive'
        }`}
      >
        <ul>
          {trackedTransactions.length > 0 ? (
            trackedTransactions.map((tx, i) => {
              return (
                <li className={`${tx.status}`} key={i}>
                  <a
                    href={getTransactionHref(tx.transactionHash)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(`transactions-dropdown.${tx.type}`)}
                  </a>
                  <span className="status">
                    {t(`transactions-dropdown.${tx.status}`)}
                  </span>
                </li>
              )
            })
          ) : (
            <li className="header">
              {t('transactions-dropdown.no-available')}
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}

function getTransactionHref(hash: string) {
  return Config.transactionExplorer.replace('{{TRANSACTION_HASH}}', hash)
}
