import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DoneAll } from '@material-ui/icons'
import BottomButton from 'Components/BottomButton'
import { Icon } from 'Components/Icon'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { useTranslation } from 'react-i18next'

import './DepositSuccessful.scss'
import { Config } from '../../Config'

const DepositSuccessful: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { contractAddress, txHash } = useParams<{
    contractAddress: string
    txHash: string
  }>()
  const { getExpectedTransactionByHash, getExplorerLink } = useTransactions()

  const expectedOutput = useMemo(() => {
    if (txHash) {
      return getExpectedTransactionByHash(txHash)
    }
    return undefined
  }, [txHash, getExpectedTransactionByHash])

  const backAction = useCallback(() => {
    let url = '/liquidity/pool'
    if (Config.blockchain === 'icon') {
      url = `/liquidity/pool/join/${contractAddress}`
    }
    return history.push(url)
  }, [contractAddress, history])

  const isIconFirstStep = useMemo(() => {
    const isIcon = Config.blockchain === 'icon'
    if (!isIcon) return false
    return (
      expectedOutput && expectedOutput.movements.deposited![0].name === 'ICX'
    )
  }, [expectedOutput])

  const title = useMemo(() => {
    return isIconFirstStep
      ? t('deposit-successful.title-icon')
      : t('deposit-successful.title')
  }, [t, isIconFirstStep])

  const backButtonTitle = useMemo(() => {
    return isIconFirstStep
      ? t('deposit-successful.go-to-step-2')
      : t('deposit-successful.deposit-more')
  }, [t, isIconFirstStep])

  return (
    <div className="DepositSuccessful">
      <h1>{title}</h1>
      <span className="DepositSuccessful__icon">
        <DoneAll />
      </span>

      {expectedOutput && (
        <>
          <div className="DepositSuccessful__summary ">
            {expectedOutput.movements.deposited!.map((deposit, i) => (
              <div key={i} className="summary-detail">
                <span className="summary-detail__logo">
                  <Icon icon={deposit.name} />
                </span>
                <span className="summary-detail__reward">
                  <span className="summary-detail__reward__flow">
                    {t('deposit-successful.deposited')}
                  </span>
                  <h2>
                    {deposit.value} {deposit.name}
                  </h2>
                </span>
              </div>
            ))}
          </div>

          <div className="DepositSuccessful__blockchain-explorer">
            <a
              href={getExplorerLink(txHash)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {txHash}
            </a>
          </div>
        </>
      )}
      <div className="Wrapper-Bottom-Button">
        <BottomButton label={backButtonTitle} onClick={backAction} />
      </div>
    </div>
  )
}

export { DepositSuccessful }
