/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../Components/Button'
import { Icon } from '../../Components/Icon'

export const ClaimSquare = () => {
  const { t } = useTranslation()

  return (
    <div className="farm">
      <div className="farm__title">
        <span className="farm__title__logo">
          <Icon icon={'UP'} />
        </span>
        <h1>UP</h1>
      </div>
      <div className="farm__liquidity">
        <span className="farm__liquidity__label">SEED holded</span>
        <span className="farm__liquidity__balances">
          <span></span>
        </span>
      </div>
      <div className="farm__earnings">
        <span className="farm__earnings__label">Unclaimed UP</span>
        <span className="farm__earnings__amount"></span>
      </div>
      <div className="farm__claim">
        <Button green disabled>
          {t('farms.claim')}
        </Button>
      </div>
    </div>
  )
}
