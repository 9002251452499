import { useCallback } from 'react'
import { ContractMethod } from '../Adapters/Contract'
import { NON_SUCCESS_RESPONSE } from '../Adapters/IAdapter'
import { useAdapter } from '../Stores/Adapter/useAdapter'
import { Farm } from '../Stores/Pools/Types'

export const useFarm = (farm: Farm) => {
  const { execute, isConnected, adapter } = useAdapter()

  const getPendingEarn = useCallback(async () => {
    if (adapter && isConnected && farm.farm_contract) {
      const pendingEarnResponse = await execute(
        farm.farm_contract,
        ContractMethod.PENDING_FEE_EARN,
        { args: [farm.contract_address, adapter.getAddress()] }
      )
      return pendingEarnResponse.value || '0'
    }
    return '0'
  }, [farm.contract_address, farm.farm_contract, adapter, isConnected, execute])

  const getUPPendingEarn = useCallback(async () => {
    if (adapter && isConnected) {
      const pendingEarnResponse = await execute(
        farm.contract_address,
        ContractMethod.PENDING_FEE_EARN
      )
      return pendingEarnResponse.value || '0'
    }
    return '0'
  }, [farm.contract_address, adapter, isConnected, execute])

  const claim = useCallback(async () => {
    if (adapter && isConnected && farm.farm_contract) {
      const claimResponse = await execute(
        farm.farm_contract,
        ContractMethod.CLAIM_FEE,
        { args: [farm.contract_address, adapter.getAddress()] },
        true
      )
      return claimResponse
    }
    return NON_SUCCESS_RESPONSE
  }, [farm.contract_address, farm.farm_contract, isConnected, adapter, execute])

  return { getUPPendingEarn, getPendingEarn, claim }
}
