import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Button from '../../Components/Button'
import { Icon } from '../../Components/Icon'
import { Config } from '../../Config'
import { useFarm } from '../../Hooks/useFarm'
import { useAdapter } from '../../Stores/Adapter/useAdapter'
import { useContract } from '../../Stores/Adapter/useContract'
import { useBalances } from '../../Stores/Balances/useBalances'
import { Farm } from '../../Stores/Pools/Types'
import { usePools } from '../../Stores/Pools/usePools'
import { BN, localiseNumber } from '../../Utils/BigNumber'
import { getPrecision } from '../../Utils/Decimals'
import { useInterval } from '../../Utils/useInterval'

const defaultPendingEarn = { UP: '0', BONUS: '0' }

export const FarmPool: React.FC<{ farm: Farm }> = ({ farm }) => {
  const [pendingEarn, setPendingEarn] = useState<typeof defaultPendingEarn>(
    defaultPendingEarn
  )
  const history = useHistory()
  const { t } = useTranslation()
  const { getBalanceByToken } = useBalances()
  const { pairs } = usePools()
  const { getPendingEarn, getUPPendingEarn, claim } = useFarm(farm)
  const { claim: nativeClaim } = useContract(farm.contract_address)
  const { isConnected } = useAdapter()
  const { addToast } = useToasts()

  const pair = useMemo(() => {
    return pairs.find((p) => p.contract_address === farm.contract_address)
  }, [pairs, farm])

  const onClaim = useCallback(async () => {
    let result: ReturnType<typeof claim | typeof nativeClaim>
    if ((farm.is_bonus || farm.is_finished) && farm.farm_contract) {
      result = claim()
    } else {
      result = nativeClaim()
    }
    result.then(({ success }) => {
      if (success) {
        addToast(<div>{t('farms.farm.claim-success')}</div>, {
          appearance: 'success'
        })
      } else {
        addToast(<div>{t('farms.farm.claim-failed')}</div>, {
          appearance: 'error'
        })
      }
    })
  }, [
    t,
    addToast,
    claim,
    nativeClaim,
    farm.is_bonus,
    farm.farm_contract,
    farm.is_finished
  ])

  const getPendingBONUS = useCallback(() => {
    return getPendingEarn().then((v) =>
      BN(v).dividedBy(getPrecision(farm.reward_token[0])).toFixed()
    )
  }, [farm.reward_token, getPendingEarn])

  const getPendingUP = useCallback(() => {
    return getUPPendingEarn().then((v) => {
      return BN(v)
        .dividedBy(getPrecision(Config.contracts.UP.address))
        .dp(10)
        .toFixed()
    })
  }, [getUPPendingEarn])

  useEffect(() => {
    if (farm.is_bonus || farm.is_finished) {
      getPendingBONUS().then((BONUS) => {
        setPendingEarn((s) => ({ ...s, BONUS }))
      })
    }
    getPendingUP().then((UP) => {
      setPendingEarn((s) => ({ ...s, UP }))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useInterval(
    () => {
      if (farm.is_bonus || farm.is_finished) {
        getPendingBONUS().then((BONUS) => {
          setPendingEarn((s) => ({ ...s, BONUS }))
        })
      }
      getPendingUP().then((UP) => {
        setPendingEarn((s) => ({ ...s, UP }))
      })
    },
    isConnected ? 5000 : null
  )

  return (
    <div className="farm">
      {farm.is_bonus && (
        <div className="farm__bonus">{t('farms.farm.BONUS')}</div>
      )}
      {farm.is_finished && (
        <div className="farm__bonus">{t('farms.farm.FINISHED')}</div>
      )}
      <div className="farm__title">
        <span className="farm__title__logo">
          <Icon icon={farm.liquidity.token_a} />
          <Icon icon={farm.liquidity.token_b} />
        </span>
        <h1>
          {farm.liquidity.token_a}-{farm.liquidity.token_b} LP
        </h1>
      </div>
      <div className="farm__earn">
        <span className="farm__earn__label">{t('farms.farm.earn')}</span>
        <span>{farm.reward_token.join(', ')}</span>
      </div>
      <div className="farm__apy">
        <span className="farm__apy__label">{t('farms.farm.last-24hr')}</span>
        <span>
          {BN(farm.liquidity.variable_annual_percentage_rate).dp(2).toString()}%
        </span>
      </div>
      <div className="farm__apy">
        <span className="farm__apy__label">APR</span>
        <span>
          {BN(farm.liquidity.annual_percentage_yearly).dp(2).toString()}%
        </span>
      </div>
      <div className="farm__liquidity">
        <span className="farm__liquidity__label">{t('farms.liquidity')}</span>
        <span className="farm__liquidity__balances">
          ${localiseNumber(farm.liquidity.total_usd)}
        </span>
      </div>
      <div className="farm__liquidity">
        <span className="farm__liquidity__label">
          {t('farms.your-liquidity')}
        </span>
        <span className="farm__liquidity__balances">
          {localiseNumber(getBalanceByToken(farm.contract_address))}{' '}
          {pair?.name}
        </span>
      </div>
      <div className="farm__earnings">
        <span className="farm__earnings__label">
          {t('farms.your-earnings')}
        </span>
        <span className="farm__earnings__amount">{pendingEarn['UP']} UP</span>
        {!!farm.reward_token[1] && (
          <span className="farm__earnings__amount">
            {pendingEarn['BONUS']} {farm.reward_token[1]}
          </span>
        )}
      </div>
      <div className="farm__claim">
        <Button green onClick={onClaim}>
          {t('farms.claim')}
        </Button>
      </div>
      <div className="farm__join">
        <Button
          green
          onClick={() =>
            history.push('/liquidity/pool/join/' + farm.contract_address)
          }
        >
          {t('farms.add-liquidity')}
        </Button>
      </div>
    </div>
  )
}
