import { atom } from 'recoil'
import { PoolsState } from './Types'

export const Pools = atom<PoolsState>({
  key: 'pools',
  default: {
    pools: [],
    pairs: [],
    farms: [],
    contracts: {}
  }
})
