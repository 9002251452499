import React, { useEffect } from 'react'
import { useAdapter } from 'Stores/Adapter/useAdapter'
import { useBalances } from 'Stores/Balances/useBalances'
import { useInterval } from 'Utils/useInterval'
import { usePools } from 'Stores/Pools/usePools'
import { Emitter, EmitterAction } from 'Utils/EventEmitter'
import { useLocation } from 'react-router-dom'
import { useTrackedTransactions } from '../../Stores/Transactions/useTrackedTransactions'
import { TrackedTransactions } from '../../Stores/Transactions/TrackedTransactions'
import { useRecoilValue } from 'recoil'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

const Updater: React.FC = ({ children }) => {
  const { connect, isConnected } = useAdapter()
  const { updateBalances, updateBalance } = useBalances()
  const { updatePools, updatePairs, updateFarms } = usePools()
  const { changeTransactionStatus } = useTrackedTransactions()
  const trackedTransactions = useRecoilValue(TrackedTransactions)
  const location = useLocation()
  const { addToast } = useToasts()
  const { t } = useTranslation()

  useEffect(() => {
    Emitter.on(EmitterAction.MESSAGE, messageHandler)
    updatePools()
    Promise.all([updatePairs(), updateFarms()]).then(
      ([{ contracts, pairs }, farms]) => {
        connect({ contracts, pairs, farms })
      }
    )
    // eslint-disable-next-line
  }, [])

  const messageHandler = (body: string | object) => {
    const envelope = typeof body === 'string' ? t(body) : JSON.stringify(body)
    addToast(<div>{envelope}</div>, { appearance: 'info' })
  }

  useEffect(() => {
    if (!isConnected) return
    updateBalances()
    Emitter.on(EmitterAction.REFRESH_BALANCES, updateBalances)
    Emitter.on(EmitterAction.BALANCE, updateBalance)
    // eslint-disable-next-line
  }, [isConnected])

  useEffect(() => {
    const currCb = changeTransactionStatus
    Emitter.on(EmitterAction.TRANSACTION_CONFIRMED, currCb)
    return () => {
      Emitter.off(EmitterAction.TRANSACTION_CONFIRMED, currCb)
    }
  }, [trackedTransactions, changeTransactionStatus])

  useInterval(() => {
    if (document.hidden) return
    if (!location.pathname.match(/liquidity\/pool$/)) updatePools()
  }, 10000)

  return <>{children}</>
}

export default Updater
