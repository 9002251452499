import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Config } from '../../Config'
import { useAdapter } from '../../Stores/Adapter/useAdapter'
import { useContract } from '../../Stores/Adapter/useContract'
import { Pair } from '../../Stores/Pools/Types'
import { useTrackedTransactions } from '../../Stores/Transactions/useTrackedTransactions'
import { BigNumber } from '../../Utils/BigNumber'
import { getPrecision } from '../../Utils/Decimals'
import { useInterval } from '../../Utils/useInterval'
import BottomButton from '../BottomButton'

import './PoolJoinButton.scss'

export const PoolJoinButton: React.FC<{
  onClick: () => void
  pair: Pair | undefined
  amountTokenA: string
}> = ({ onClick, pair, amountTokenA }) => {
  const { t } = useTranslation()
  return ['ethereum'].includes(Config.blockchain) ? (
    <EthereumPoolJoinButton
      pair={pair}
      onClick={onClick}
      amountTokenA={amountTokenA}
    />
  ) : (
    <BottomButton label={t('liquidity.pool-join.submit')} onClick={onClick} />
  )
}

const EthereumPoolJoinButton: React.FC<{
  onClick: () => void
  pair: Pair | undefined
  amountTokenA: string
}> = ({ onClick, pair, amountTokenA }) => {
  const { t } = useTranslation()
  const { adapter } = useAdapter()
  const { getAllowance, approveAllowance } = useContract(pair!.contract_address)
  const [hasAllowance, setHasAllowance] = useState(false)
  const { addTrackedTransaction } = useTrackedTransactions()

  const estimatedAllowance = useMemo(
    () =>
      BigNumber(amountTokenA)
        .multipliedBy(getPrecision(pair!.token_a))
        .decimalPlaces(0)
        .toFixed(),
    [amountTokenA, pair]
  )

  const onAllowanceClick = useCallback(() => {
    if (!BigNumber(estimatedAllowance).isGreaterThan(0)) return

    approveAllowance(estimatedAllowance).then((res) => {
      if (res) {
        addTrackedTransaction({
          address: adapter!.getAddress(),
          type: 'APPROVAL',
          description: pair!.token_a,
          transactionHash: res.hash,
          notified: false,
          status: 'PENDING'
        })
      }
    })
  }, [
    approveAllowance,
    addTrackedTransaction,
    estimatedAllowance,
    adapter,
    pair
  ])

  const checkAllowance = useCallback(() => {
    if (!pair) return
    getAllowance(pair.token_a_address).then((currentAllowance) => {
      if (
        BigNumber(currentAllowance).isGreaterThanOrEqualTo(estimatedAllowance)
      ) {
        setHasAllowance(true)
      } else {
        if (hasAllowance) setHasAllowance(false)
      }
    })
  }, [getAllowance, pair, hasAllowance, estimatedAllowance])

  useEffect(() => {
    checkAllowance()
    // eslint-disable-next-line
  }, [pair])

  useInterval(() => {
    checkAllowance()
  }, 4000)

  return (
    <div className="EthereumPerformSwapButton">
      <BottomButton
        options={{ disabled: hasAllowance }}
        label={t('trade.approve', { token: pair!.token_a })}
        onClick={onAllowanceClick}
      />
      <BottomButton
        options={{ disabled: !hasAllowance }}
        label={t('liquidity.pool-join.submit')}
        onClick={onClick}
      />
    </div>
  )
}
