import SHA3 from 'crypto-js/sha3'

const sha3 = (value: string) => {
  return SHA3(value, {
    outputLength: 256
  }).toString()
}

export const isEthAddress = (address: string) => {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    return false
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(address) ||
    /^(0x)?[0-9A-F]{40}$/.test(address)
  ) {
    return true
  } else {
    return isChecksumAddress(address)
  }
}

const isChecksumAddress = function (address: string) {
  address = address.replace('0x', '')
  let addressHash = sha3(address.toLowerCase())

  for (let i = 0; i < 40; i++) {
    if (
      (parseInt(addressHash[i], 16) > 7 &&
        address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 &&
        address[i].toLowerCase() !== address[i])
    ) {
      return false
    }
  }
  return true
}
