import { useCallback } from 'react'
import { Pool, Pair, Contract, Farm } from './Types'
import { useAPI } from 'Stores/API/useAPI'
import { useRecoilState } from 'recoil'
import { Pools } from './Pools'

export const usePools = () => {
  const [{ pools, pairs, farms, contracts }, setPoolsState] = useRecoilState(
    Pools
  )
  const { getPoolsData, getPairsData, getFarmsData } = useAPI()

  const addPool = useCallback(
    (pool: Pool) => {
      setPoolsState((state) => ({ ...state, pools: [...state.pools, pool] }))
    },
    [setPoolsState]
  )

  const addPair = useCallback(
    (pair: Pair) => {
      setPoolsState((state) => ({ ...state, pairs: [...state.pairs, pair] }))
    },
    [setPoolsState]
  )

  const setFarms = useCallback(
    (farms: Farm[]) => {
      setPoolsState((state) => ({ ...state, farms }))
    },
    [setPoolsState]
  )

  const addContract = useCallback(
    (contract: { [token: string]: Contract }) => {
      setPoolsState((state) => ({
        ...state,
        contracts: { ...state.contracts, ...contract }
      }))
    },
    [setPoolsState]
  )

  const clearPools = useCallback(() => {
    setPoolsState((state) => ({ ...state, pools: [] }))
  }, [setPoolsState])

  const updatePools = useCallback(async () => {
    return getPoolsData().then((pools) => {
      clearPools()
      pools.forEach(addPool)
      return pools
    })
  }, [getPoolsData, addPool, clearPools])

  const updatePairs = useCallback(async () => {
    return getPairsData().then(({ pairs, contracts }) => {
      pairs.forEach(addPair)
      addContract(contracts)
      return { pairs, contracts }
    })
  }, [getPairsData, addPair, addContract])

  const updateFarms = useCallback(async () => {
    return getFarmsData().then((farms) => {
      setFarms(farms)
      return farms
    })
  }, [getFarmsData, setFarms])

  return {
    updatePairs,
    updatePools,
    updateFarms,
    farms,
    pools,
    pairs,
    contracts
  }
}
