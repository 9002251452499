import { atom } from 'recoil'
import { AdapterBalance } from '../../Adapters/IAdapter'

type BalancesState = {
  balances: AdapterBalance[]
}

export const Balances = atom<BalancesState>({
  key: 'balances',
  default: {
    balances: []
  },
  dangerouslyAllowMutability: true
})
