import { useCallback, useState, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { useAdapter } from './useAdapter'
import { ContractMethod } from '../../Adapters/Contract'
import { NON_SUCCESS_RESPONSE } from '../../Adapters/IAdapter'
import { Config } from 'Config'
import { useInterval } from 'Utils/useInterval'
import { getDecimals, getPrecision } from 'Utils/Decimals'

export const useUP = () => {
  const { adapter, execute } = useAdapter()
  const [upPrice, setUpPrice] = useState('0')

  const updateUpPrice = async () => {
    if (adapter) {
      const upResponse = await execute(
        Config.contracts.UP.address,
        ContractMethod.GET_VIRTUAL_PRICE
      )
      if (upResponse.success) {
        const price = new BigNumber(upResponse.value)
          .dividedBy(getPrecision(Config.contracts.UP.address))
          .toFixed()
        setUpPrice(price)
      }
    }
  }

  useEffect(() => {
    updateUpPrice()
    // eslint-disable-next-line
  }, [adapter])

  useInterval(() => {
    updateUpPrice()
  }, 6000)

  const getEstimatedRedeem = useCallback(
    async (redeemAmount: string) => {
      if (adapter) {
        return new BigNumber(redeemAmount)
          .multipliedBy(new BigNumber(upPrice))
          .decimalPlaces(getDecimals('UP'))
          .toFixed()
      }
      return '0'
    },
    [adapter, upPrice]
  )

  const burnUP = useCallback(
    async (redeemAmount: string) => {
      if (adapter) {
        const upResponse = await execute(
          Config.contracts.UP.address,
          ContractMethod.BURN,
          {
            args: [
              new BigNumber(redeemAmount)
                .multipliedBy(getPrecision(Config.contracts.UP.address))
                .toFixed()
            ]
          },
          true
        )
        return upResponse
      }
      return {
        ...NON_SUCCESS_RESPONSE,
        params: {},
        functionName: ContractMethod.BURN
      }
    },
    [adapter, execute]
  )

  return {
    getEstimatedRedeem,
    burnUP
  }
}
