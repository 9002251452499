import React, { useState, useMemo } from 'react'
import { Config } from 'Config'
import Input from 'Components/Input'
import { Table, TableRow } from 'Components/Table'
import { useBalances } from 'Stores/Balances/useBalances'
import { useUP } from 'Stores/Adapter/useUP'
import BottomButton from 'Components/BottomButton'
import { isNumberAndNonZero, truncateDecimals, isNaN } from 'Utils/BigNumber'
import BigNumber from 'bignumber.js'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useLoading } from 'Stores/Loading/useLoading'
import UnfiLogo from 'Assets/Icons/up_doodle.png'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { Trans, useTranslation } from 'react-i18next'

import './Up.scss'

const Up: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { addToast } = useToasts()
  const { getBalanceByToken, balances } = useBalances()
  const { burnUP, getEstimatedRedeem } = useUP()
  const { isLoading } = useLoading()
  const [redeemAmount, setRedeemAmount] = useState('')
  const [estimatedRedeem, setEstimatedRedeem] = useState('0')
  const { addExpectedTransaction } = useTransactions()

  const upBalance = useMemo(
    () => getBalanceByToken('UP'),
    // eslint-disable-next-line
    [balances, getBalanceByToken]
  )

  const onClickApprove = async () => {
    if (isNumberAndNonZero(redeemAmount) && !isLoading) {
      if (new BigNumber(redeemAmount).isGreaterThan(upBalance)) {
        return addToast(<div>{t('up.invalid-amount')}</div>, {
          appearance: 'warning'
        })
      }

      const burn = await burnUP(redeemAmount)

      addExpectedTransaction({
        transaction: burn,
        movements: {
          sent: { name: 'UP', value: redeemAmount },
          received: { name: Config.blockchainToken, value: estimatedRedeem }
        }
      })

      if (burn.success) {
        history.push(`/up/success/${burn.hash}`)
      } else {
        history.push('/failed')
      }
    }
  }

  const onInputChange = async (val: string) => {
    if (isNaN(val) && val !== '') return
    const value = val || '0'
    setRedeemAmount(truncateDecimals(value))
    const estimated = await getEstimatedRedeem(value)
    setEstimatedRedeem(estimated)
  }

  return (
    <div className="Up">
      <div className="Up__header">
        <img src={UnfiLogo} alt="Unifi" />
        <span>
          <h1>{t('up.title')}</h1>
          <p>{t('up.p-1')}</p>
          <p>
            <Trans i18nKey="for-more-info-press-here">
              For more information press{' '}
              <a
                href="https://unifiprotocol.zendesk.com/hc/en-us"
                title="more information"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Trans>
          </p>
        </span>
      </div>

      <div className="Up__redeem">
        <Input
          placeholder={t('up.enter-amount')}
          balance={upBalance}
          onChange={onInputChange}
          value={redeemAmount}
          max
          exactMax
        />
      </div>

      <div className="Up__summary">
        <div className="Up__summary__title">{t('up.user-expected')}</div>
        <Table>
          <TableRow columns={[Config.blockchainToken, estimatedRedeem]} />
        </Table>
      </div>

      <div className="Wrapper-Bottom-Button">
        <BottomButton label={t('up.submit')} onClick={onClickApprove} />
      </div>
    </div>
  )
}

export { Up }
