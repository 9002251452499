import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { Emitter, EmitterAction } from '../../Utils/EventEmitter'
import { useAdapter } from '../Adapter/useAdapter'
import { TrackedTransaction, TrackedTransactions } from './TrackedTransactions'

export const useTrackedTransactions = () => {
  const { adapter } = useAdapter()
  const [trackedTransactions, setTrackedTransactions] = useRecoilState(
    TrackedTransactions
  )

  const changeTransactionStatus = useCallback(
    ({
      transactionHash,
      status
    }: {
      transactionHash: TrackedTransaction['transactionHash']
      status: TrackedTransaction['status']
    }) => {
      const tTransactions = [...trackedTransactions]
      const mTransaction = tTransactions.find(
        (t) => t.transactionHash === transactionHash
      )
      if (mTransaction) {
        mTransaction.status = status
        setTrackedTransactions(tTransactions)
      }
    },
    [setTrackedTransactions, trackedTransactions]
  )

  const markAllAsNotified = useCallback(() => {
    const tTransactions = [...trackedTransactions].map((t) => ({
      ...t,
      notified: true
    }))
    setTrackedTransactions(tTransactions)
  }, [setTrackedTransactions, trackedTransactions])

  const addTrackedTransaction = (tTransaction: TrackedTransaction) => {
    if (!tTransaction.transactionHash) return

    setTrackedTransactions((curr) => [tTransaction, ...curr])

    if (adapter && adapter.waitForTransaction) {
      adapter
        .waitForTransaction(tTransaction.transactionHash)
        .then((status) => {
          Emitter.emit(EmitterAction.TRANSACTION_CONFIRMED, {
            transactionHash: tTransaction.transactionHash,
            status
          })
        })
    }
  }

  return { addTrackedTransaction, changeTransactionStatus, markAllAsNotified }
}
