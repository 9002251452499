import { atom, selector } from 'recoil'

type TransactionStatus = 'FAILED' | 'SUCCESS' | 'PENDING'

export type TransactionType = 'APPROVAL' | 'SWAP' | 'DEPOSIT' | 'WITHDRAW'

export interface TrackedTransaction {
  address: string
  type: TransactionType
  description: string
  transactionHash: string
  notified: boolean
  status: TransactionStatus
}

export const TrackedTransactions = atom<TrackedTransaction[]>({
  key: 'trackedTransactions',
  default: [],
  dangerouslyAllowMutability: true
})

export const pendingTrackedTransactions = selector({
  key: 'pendingTrackedTransactions',
  get: ({ get }) => {
    const transactions = get(TrackedTransactions)
    return transactions.filter((t) => t.status === 'PENDING')
  }
})
