import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BottomButton from 'Components/BottomButton'
import { Table, TableRow, TableHeader } from 'Components/Table'
import Input from 'Components/Input'
import Button from 'Components/Button'
import { useAdapter } from 'Stores/Adapter/useAdapter'
import { ContractMethod } from 'Adapters/Contract'
import { useBalances } from 'Stores/Balances/useBalances'
import {
  BigNumber as BN,
  localiseNumber,
  isNumberAndNonZero,
  truncateDecimals,
  isNaN,
  toBNFixed,
  toHex
} from 'Utils/BigNumber'
import { useInterval } from 'Utils/useInterval'
import { useLoading } from 'Stores/Loading/useLoading'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { useToasts } from 'react-toast-notifications'
import { getDecimals, getPrecision } from 'Utils/Decimals'
import { useTranslation } from 'react-i18next'
import { usePools } from '../../Stores/Pools/usePools'
import { useTrackedTransactions } from '../../Stores/Transactions/useTrackedTransactions'
import { HelpOutline } from '@material-ui/icons'

import './IconPoolJoin.scss'

const IconPoolJoin: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { contractAddress } = useParams<{ contractAddress: string }>()
  const { addToast } = useToasts()
  const { execute, adapter } = useAdapter()
  const { getBalanceByToken } = useBalances()
  const { isLoading } = useLoading()
  const { addExpectedTransaction } = useTransactions()
  const [tokenAInput, setTokenAInput] = useState('')
  const [tokenBInput, setTokenBInput] = useState('')
  const [pairDetail, setPairDetail] = useState({
    name: '',
    token_a: '',
    token_b: '',
    price: '0',
    againstPrice: '0',
    totalSupply: 0,
    shareOfPool: 0,
    uTokenBalance: '0',
    contractAgainstTokenBalance: '0',
    contractTokenBalance: '0',
    credit: '0'
  })
  const { pairs } = usePools()
  const { addTrackedTransaction } = useTrackedTransactions()

  const pairConfig = useMemo(() => {
    return pairs.find((p) => p.contract_address === contractAddress)
  }, [contractAddress, pairs])

  const updatePair = useCallback(async () => {
    if (adapter && contractAddress && pairConfig) {
      const [
        ulPrice,
        tSupply,
        uPairTokenBalance,
        uPairAgainstTokenBalance,
        currentCredit
      ] = await Promise.all([
        execute(contractAddress, ContractMethod.GET_PRICE),
        execute(contractAddress, ContractMethod.TOTAL_SUPPLY),
        adapter
          .getBalanceOf(pairConfig.token_a_address, contractAddress)
          .then((x) => x.balance),
        adapter
          .getBalanceOf(pairConfig.token_b_address, contractAddress)
          .then((x) => x.balance),
        execute(contractAddress, ContractMethod.CREDIT_OF)
      ])

      const uTokenBalance = getBalanceByToken(contractAddress)

      const totalSupply = BN(tSupply.value)
        .dividedBy(getPrecision(contractAddress))
        .decimalPlaces(getDecimals(contractAddress))
        .toNumber()

      const contractTokenBalance = BN(uTokenBalance)
        .dividedBy(totalSupply)
        .multipliedBy(uPairTokenBalance)
        .toFixed()

      const contractAgainstTokenBalance = BN(uTokenBalance)
        .dividedBy(totalSupply)
        .multipliedBy(uPairAgainstTokenBalance)
        .toFixed()

      const price = BN(ulPrice.value)
        .dividedBy(getPrecision(pairConfig.token_b))
        .decimalPlaces(getDecimals(pairConfig.name))
        .toFixed()

      const shareOfPool =
        BN(uTokenBalance).dividedBy(totalSupply).multipliedBy(100).toNumber() ||
        0

      const againstPrice = BN(1)
        .dividedBy(price)
        .decimalPlaces(getDecimals(pairConfig.token_b))
        .toFixed()

      const credit = BN(currentCredit.value)
        .dividedBy(getPrecision(contractAddress))
        .decimalPlaces(getDecimals(contractAddress))
        .toFixed()

      setPairDetail((detail) => ({
        ...detail,
        price,
        againstPrice,
        totalSupply,
        uTokenBalance,
        contractTokenBalance,
        contractAgainstTokenBalance,
        shareOfPool,
        credit,
        ...pairConfig
      }))
    }
    // eslint-disable-next-line
  }, [execute, contractAddress, adapter, pairConfig])

  const tokenA = useMemo(() => {
    if (pairConfig) {
      return getBalanceByToken(pairConfig!.token_a_address)
    }
    return '0'
  }, [getBalanceByToken, pairConfig])

  const tokenB = useMemo(() => {
    if (pairConfig) {
      return getBalanceByToken(pairConfig?.token_b_address)
    }
    return '0'
  }, [getBalanceByToken, pairConfig])

  const onClickDeposit = async () => {
    if (
      adapter &&
      !isLoading &&
      isNumberAndNonZero(tokenBInput) &&
      pairConfig
    ) {
      if (BN(tokenBInput).isGreaterThan(tokenB)) {
        return addToast(<div>{t('amount-greater-than-balances')}</div>, {
          appearance: 'warning'
        })
      }

      const depositAmount = BN(tokenBInput)
        .multipliedBy(getPrecision(pairConfig!.token_b_address))
        .decimalPlaces(getDecimals(pairConfig!.token_b_address))
        .toNumber()

      const num = toHex(Math.trunc(BN(depositAmount).toNumber()))
      const depositResult = await execute(
        contractAddress,
        ContractMethod.ADD_CREDIT,
        { callValue: num },
        true
      )

      addTrackedTransaction({
        address: adapter!.getAddress(),
        type: 'DEPOSIT',
        description: '',
        transactionHash: depositResult.hash,
        notified: false,
        status: 'PENDING'
      })

      if (depositResult && depositResult.success) {
        addExpectedTransaction({
          transaction: depositResult,
          movements: {
            deposited: [
              {
                name: pairConfig.token_b,
                value: tokenBInput
              }
            ]
          }
        })

        history.push(
          `/liquidity/pool/join/${contractAddress}/success/${depositResult.hash}`
        )
      } else {
        history.push('/failed')
      }
    }
  }

  const onClickDepositAndAuthorize = async () => {
    if (
      adapter &&
      !isLoading &&
      isNumberAndNonZero(tokenAInput) &&
      pairConfig
    ) {
      if (BN(tokenAInput).isGreaterThan(maxAllocation)) {
        return addToast(
          <div>{t('icon-pool-join.amount-greater-than-allocation')}</div>,
          {
            appearance: 'warning'
          }
        )
      }

      const depositAmount = toHex(
        Math.trunc(
          BN(tokenAInput)
            .decimalPlaces(getDecimals(pairConfig!.token_a_address))
            .multipliedBy(getPrecision(pairConfig!.token_a_address))
            .decimalPlaces(0)
            .toNumber()
        )
      )

      const depositResult = await execute(
        contractAddress,
        ContractMethod.DEPOSIT_SUPPLY,
        { args: [depositAmount] },
        true
      )

      addTrackedTransaction({
        address: adapter!.getAddress(),
        type: 'DEPOSIT',
        description: '',
        transactionHash: depositResult.hash,
        notified: false,
        status: 'PENDING'
      })

      if (depositResult && depositResult.success) {
        addExpectedTransaction({
          transaction: depositResult,
          movements: {
            deposited: [
              {
                name: pairConfig.token_a,
                value: tokenAInput
              }
            ]
          }
        })

        history.push(
          `/liquidity/pool/join/${contractAddress}/success/${depositResult.hash}`
        )
      } else {
        history.push('/failed')
      }
    }
  }

  const onClickWithdraw = async () => {
    if (
      adapter &&
      !isLoading &&
      pairConfig &&
      !BN(pairDetail.credit).isZero()
    ) {
      const withdrawResult = await execute(
        contractAddress,
        ContractMethod.WITHDRAW_CREDIT,
        {},
        true
      )

      addTrackedTransaction({
        address: adapter!.getAddress(),
        type: 'WITHDRAW',
        description: '',
        transactionHash: withdrawResult.hash,
        notified: false,
        status: 'PENDING'
      })

      if (withdrawResult && withdrawResult.success) {
        addExpectedTransaction({
          transaction: withdrawResult,
          movements: {
            withdrawn: [
              {
                name: pairConfig.token_b,
                value: pairDetail.credit
              }
            ]
          }
        })

        history.push(
          `/liquidity/withdraw-credits/${contractAddress}/success/${withdrawResult.hash}`
        )
      } else {
        history.push('/failed')
      }
    }
  }

  const maxAllocation = useMemo(() => {
    if (BN(pairDetail.credit).isGreaterThan(0)) {
      const maxAllocationByCredit = BN(pairDetail.credit)
        .multipliedBy(pairDetail.againstPrice)
        .decimalPlaces(getDecimals(contractAddress))
        .toFixed()
      return BN(maxAllocationByCredit).isGreaterThan(tokenA)
        ? BN(tokenA)
            .multipliedBy(0.99999)
            .decimalPlaces(getDecimals(contractAddress))
            .toFixed()
        : maxAllocationByCredit
    }
    return '0'
  }, [pairDetail, contractAddress, tokenA])

  const hasCredit = useMemo(() => {
    return BN(pairDetail.credit).isGreaterThan(0)
  }, [pairDetail])

  const estimatedAllocation = useMemo(() => {
    if (BN(tokenBInput).isGreaterThan(0)) {
      const estimatedByInput = BN(tokenBInput)
        .multipliedBy(pairDetail.againstPrice)
        .decimalPlaces(getDecimals(contractAddress))
        .toFixed()
      return BN(estimatedByInput).isGreaterThan(tokenA)
        ? tokenA
        : estimatedByInput
    }
    return '0'
  }, [pairDetail, contractAddress, tokenBInput, tokenA])

  const onInputChange = (inputName: 'TokenA' | 'TokenB') => (value: string) => {
    if (isNaN(value) && value !== '') return
    const val = truncateDecimals(value || '0')
    if (inputName === 'TokenA') {
      setTokenAInput(truncateDecimals(val))
    } else {
      setTokenBInput(val)
    }
  }

  useEffect(() => {
    updatePair()
    // eslint-disable-next-line
  }, [adapter])

  useInterval(() => {
    updatePair()
  }, 3000)

  return (
    <div className="IconPoolJoin">
      <div className="IconPoolJoin__band IconPoolJoin__band-summary">
        <Table>
          <TableHeader
            columns={[
              t('liquidity.pool-join.AperB', {
                tokenA: pairDetail.token_a,
                tokenB: pairDetail.token_b
              }),
              t('liquidity.pool-join.shareOfPool'),
              t('liquidity.pool-join.BperA', {
                tokenA: pairDetail.token_a,
                tokenB: pairDetail.token_b
              })
            ]}
          />
          <TableRow
            columns={[
              <span>{toBNFixed(pairDetail.price)}</span>,
              <span className="color-dark-green">
                {localiseNumber(pairDetail.shareOfPool)} %
              </span>,
              <span>{toBNFixed(pairDetail.againstPrice)}</span>
            ]}
          />
        </Table>
      </div>

      <div className="IconPoolJoin__steps">
        <div className="IconPoolJoin__steps__help">
          <Button
            green
            onClick={() =>
              window.open(
                'https://unifiprotocol.zendesk.com/hc/en-us/articles/360054411671'
              )
            }
          >
            {t('icon-pool-join.need-help')}
          </Button>
        </div>

        <div className="IconPoolJoin__steps__step">
          <h1>
            <span className="hide-for-mobile">1</span>
            {t('icon-pool-join.title.deposit-base-token')}
          </h1>
          <div className="IconPoolJoin__steps__step__content">
            <div className="step-amount">
              <div className="step-amount__info">
                {hasCredit ? (
                  <Button green>
                    {t('icon-pool-join.current-credit', {
                      amount: pairDetail.credit,
                      token: pairDetail.token_b
                    })}
                  </Button>
                ) : (
                  <Button green>
                    {t('icon-pool-join.estimated-trading-token', {
                      amount: estimatedAllocation,
                      token: pairDetail.token_a
                    })}
                  </Button>
                )}
                <Button green icon={pairDetail.token_b}>
                  {pairDetail.token_b}
                </Button>
              </div>
              <Input
                placeholder={t('liquidity.pool-join.input-amount-deposit')}
                balance={tokenB}
                value={tokenBInput}
                onChange={onInputChange('TokenB')}
                decimals={getDecimals(pairDetail.token_b)}
                max
              />
            </div>

            <div className="step-actions">
              <a
                className="step-actions__help"
                href="https://unifiprotocol.zendesk.com/hc/en-us/articles/360054411871"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{t('icon-pool-join.withdraw-credits-help')}</span>
                <HelpOutline />
              </a>
              <Button
                onClick={onClickWithdraw}
                disabled={BN(pairDetail.credit).isZero()}
              >
                {t('icon-pool-join.withdraw-credit')}
              </Button>
              <Button onClick={onClickDeposit}>
                {t('liquidity.pool-join.icon.deposit-token', {
                  token: pairDetail.token_b
                })}
              </Button>
            </div>
          </div>
        </div>

        {hasCredit && (
          <div className="IconPoolJoin__steps__step">
            <h1>
              <span className="hide-for-mobile">2</span>
              {t('icon-pool-join.title.deposit-trading-token')}
            </h1>
            <div className="IconPoolJoin__steps__step__content">
              <div className="step-amount">
                <div className="step-amount__info">
                  <Button green onClick={() => setTokenAInput(maxAllocation)}>
                    {t('icon-pool-join.max-allocation-button', {
                      amount: maxAllocation,
                      token: pairDetail.token_a
                    })}
                  </Button>
                  <Button green icon={pairDetail.token_a}>
                    {pairDetail.token_a}
                  </Button>
                </div>
                <Input
                  placeholder={t('liquidity.pool-join.input-amount-deposit')}
                  balance={tokenA}
                  value={tokenAInput}
                  onChange={onInputChange('TokenA')}
                  decimals={getDecimals(pairDetail.token_a)}
                  customMax={maxAllocation}
                  max
                />
              </div>

              <div className="step-actions">
                <Button onClick={onClickDepositAndAuthorize}>
                  {t('liquidity.pool-join.icon.deposit-base-token', {
                    token: pairDetail.token_a
                  })}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="IconPoolJoin__band IconPoolJoin__band-liquidity">
        <div>{t('liquidity.pool-join.user-liquidity')}</div>
        <Table>
          <TableRow
            columns={[
              <span>{pairDetail.name}</span>,
              <div className="text-align-right">{pairDetail.uTokenBalance}</div>
            ]}
          />
          <TableRow
            columns={[
              <span>{pairDetail.token_a}</span>,
              <div className="text-align-right">
                {pairDetail.contractTokenBalance}
              </div>
            ]}
          />
          <TableRow
            columns={[
              <span>{pairDetail.token_b}</span>,
              <div className="text-align-right">
                {pairDetail.contractAgainstTokenBalance}
              </div>
            ]}
          />
        </Table>
      </div>

      <div className="Wrapper-Bottom-Button">
        <BottomButton
          label={t('liquidity.pool-join.icon.back')}
          onClick={() => history.push('/liquidity/pool')}
        />
      </div>
    </div>
  )
}

export { IconPoolJoin }
