export enum Contract {
  UP = 'UP'
}

export enum ContractMethod {
  BURN = 'burn',
  GET_VIRTUAL_PRICE = 'getVirtualPrice',
  TRANSFER = 'transfer',
  BALANCE_OF = 'balanceOf',
  ALLOWANCE = 'allowance',
  APPROVE = 'approve',
  GET_ALLOWANCE = 'getAllowance',
  DEPOSIT_SUPPLY = 'DepositSupply',
  WITHDRAW_SUPPLY = 'WithdrawSupply',
  GET_PRICE = 'getPrice',
  TOTAL_SUPPLY = 'totalSupply',
  GET_BASE_FACTOR = 'getBasefactor',
  GET_MAX_TRANSACTION = 'getMaxTransaction',
  GET_FEE = 'getFEE',
  GET_STATE = 'getSTATE',
  GET_FEE_STATE = 'getFeeState',
  GET_POOL_STATE = 'getPoolStatie',
  STATE_OF = 'stateOf',
  BUY = 'Buy',
  SELL = 'Sell',
  CLAIMABLE_FEE = 'totalProfitOf',
  CLAIM_FEE = 'ClaimFee',
  PENDING_FEE_EARN = 'pendingFeeEarn',
  GET_ESTIMATED_BUY_RECEIVE_AMOUNT = 'getEstimatedBuyReceiveAmount',
  GET_ESTIMATED_SELL_RECEIVE_AMOUNT = 'getEstimatedSellReceiveAmount',
  MAX_RATIO = 'MaxRatio',
  DEPOSIT_UP = 'depositUPToken',
  DEPOSIT_SEED_TOKEN = 'depositSEEDToken',
  BNB_ADDRESS_OF = 'bnbAddressOf',
  SEED_DEPOSITED_OF = 'SEEDDepositedOf',
  JOIN_FARM = 'joinFarm',
  CLAIM_UP = 'claimUP',
  SCORE_OF = 'scoreOf',
  SCORING = 'scoring',
  CREDIT_OF = 'creditOf', // ICX
  ADD_CREDIT = 'addCredit', // ICX
  WITHDRAW_CREDIT = 'withdrawCredit' // ICX
}
