import React, { useMemo } from 'react'
import { Table, TableRow } from '../Table'
import { ArrowForwardIos } from '@material-ui/icons'
import { SwapState } from 'Stores/Swap/Swap'
import { Icon } from 'Components/Icon'
import { Config } from 'Config'
import { BigNumber, localiseNumber, truncateDecimals } from 'Utils/BigNumber'
import { Pair } from '../../Stores/Pools/Types'
import { useTranslation } from 'react-i18next'
import { getDecimals } from '../../Utils/Decimals'

import './SwapDetail.scss'

type SwapDetailProps = Pick<SwapState, 'tokenA' | 'tokenB'> & {
  maxTransaction: string
  selectedPair: Pair | undefined
  tradeFee: string
  price: string
  againstPrice: string
  slippage: string
  amountTokenB: string
}

const SwapDetail: React.FC<SwapDetailProps> = ({
  tokenA,
  tokenB,
  maxTransaction,
  selectedPair,
  tradeFee,
  price,
  againstPrice,
  slippage,
  amountTokenB
}) => {
  const { t } = useTranslation()

  const tokenFee = useMemo(() => {
    if (tokenB !== Config.blockchainToken) {
      return tokenA
    }
    return Config.blockchainToken
  }, [tokenA, tokenB])

  if (selectedPair) {
    return (
      <span className="Swap-Detail">
        <div className="Swap-Detail__summary">
          <Table>
            <tbody>
              <TableRow
                columns={[
                  t('swap-detail.price'),
                  <div className="text-align-right">
                    {t('swap-detail.price-detail', { price, tokenB, tokenA })}
                  </div>
                ]}
              />
              <TableRow
                columns={[
                  t('swap-detail.against-price'),
                  <div className="text-align-right">
                    {t('swap-detail.price-detail', {
                      price: againstPrice,
                      tokenB: tokenA,
                      tokenA: tokenB
                    })}
                  </div>
                ]}
              />
              {Config.blockchain === 'ethereum' && (
                <TableRow
                  columns={[
                    t('swap-detail.minimum-received'),
                    <div className="text-align-right">
                      {amountTokenB
                        ? BigNumber(amountTokenB)
                            .multipliedBy(0.995)
                            .decimalPlaces(getDecimals(tokenB))
                            .toFixed()
                        : 0}{' '}
                      {tokenB}
                    </div>
                  ]}
                />
              )}
              <TableRow
                columns={[
                  t('swap-detail.max-trade-size'),
                  <div className="text-align-right">
                    {localiseNumber(truncateDecimals(maxTransaction))} {tokenA}
                  </div>
                ]}
              />
              <TableRow
                columns={[
                  t('swap-detail.trade-fee'),
                  <div className="text-align-right">
                    {truncateDecimals(tradeFee)} {tokenFee}
                  </div>
                ]}
              />
              <TableRow
                columns={[
                  t('swap-detail.slippage'),
                  <div className="text-align-right slippage">{slippage} %</div>
                ]}
              />
            </tbody>
          </Table>
        </div>
        <div className="Swap-Detail__sequence">
          <div>{t('swap-detail.transaction-sequence')}</div>
          <div className="Swap-Detail__sequence__detail">
            <div>
              <span className="token-logo">
                <Icon icon={tokenA} />
              </span>
              <span className="token">
                <span className="token__flow">{t('swap-detail.from')}</span>
                <h2>{tokenA}</h2>
              </span>
            </div>
            <span className="arrow">
              <ArrowForwardIos />
            </span>
            <div>
              <span className="token-logo">
                <Icon icon={tokenB} />
              </span>
              <span className="token">
                <span className="token__flow">{t('swap-detail.to')}</span>
                <h2>{tokenB}</h2>
              </span>
            </div>
          </div>
        </div>
      </span>
    )
  }

  return (
    <span className="SwapDetail-message">
      Please select the tokens to trade
    </span>
  )
}

export default SwapDetail
