import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DoneAll } from '@material-ui/icons'
import BottomButton from 'Components/BottomButton'
import { Icon } from 'Components/Icon'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { useTranslation } from 'react-i18next'

import './UpSuccesful.scss'

const UpSuccesful: React.FC = () => {
  const { t } = useTranslation()
  const { txHash } = useParams<{ txHash: string }>()
  const { getExpectedTransactionByHash, getExplorerLink } = useTransactions()

  const expectedOutput = useMemo(() => {
    if (txHash) {
      return getExpectedTransactionByHash(txHash)
    }
    return undefined
  }, [txHash, getExpectedTransactionByHash])

  return (
    <div className="UpSuccesful">
      <h1>{t('up-successful.title')}</h1>
      <span className="UpSuccesful__icon">
        <DoneAll />
      </span>

      {expectedOutput && (
        <>
          <div className="UpSuccesful__summary summary-detail">
            <span className="summary-detail__logo">
              <Icon icon={expectedOutput.movements.received!.name} />
            </span>
            <span className="summary-detail__reward">
              <span className="summary-detail__reward__flow">
                {t('up-successful.received')}
              </span>
              <h2>
                {expectedOutput.movements.received!.value}{' '}
                {expectedOutput.movements.received!.name}
              </h2>
            </span>
          </div>
          <div className="UpSuccesful__blockchain-explorer">
            <a
              href={getExplorerLink(txHash)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {txHash}
            </a>
          </div>
        </>
      )}

      <div className="Wrapper-Bottom-Button">
        <UpSuccesfulButton />
      </div>
    </div>
  )
}

const UpSuccesfulButton: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <BottomButton
      label={t('up-successful.back')}
      onClick={() => history.push('/')}
    />
  )
}

export { UpSuccesful, UpSuccesfulButton }
