import { atom } from 'recoil'

type ParametersState = {
  unlimitedApproval: boolean
}

export const Parameters = atom<ParametersState>({
  key: 'parameters',
  default: {
    unlimitedApproval: false
  }
})
