import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContractMethod } from '../../Adapters/Contract'
import { Config } from '../../Config'
import { useContract } from '../../Stores/Adapter/useContract'
import { Pair } from '../../Stores/Pools/Types'
import { BigNumber } from '../../Utils/BigNumber'
import { getPrecision } from '../../Utils/Decimals'
import { useInterval } from '../../Utils/useInterval'
import BottomButton from '../BottomButton'

import './PerformSwapButton.scss'

export const PerformSwapButton: React.FC<{
  onClick: () => void
  pair: Pair | undefined
  flow: ContractMethod.BUY | ContractMethod.SELL
  amountTokenA: string
  swapReady: boolean
  submitMessage: string
}> = ({ onClick, pair, flow, amountTokenA, swapReady, submitMessage }) => {
  return (
    <div className="Wrapper-Bottom-Button">
      {['ethereum'].includes(Config.blockchain) ? (
        swapReady && (
          <EthereumPerformSwapButton
            pair={pair}
            onClick={onClick}
            flow={flow}
            amountTokenA={amountTokenA}
          />
        )
      ) : (
        <BottomButton
          label={submitMessage}
          onClick={onClick}
          options={{ disabled: !swapReady }}
        />
      )}
    </div>
  )
}

const EthereumPerformSwapButton: React.FC<{
  onClick: () => void
  pair: Pair | undefined
  flow: ContractMethod.BUY | ContractMethod.SELL
  amountTokenA: string
}> = ({ onClick, pair, flow, amountTokenA }) => {
  const { t } = useTranslation()
  const { getAllowance, approveAllowance } = useContract(pair!.contract_address)
  const [hasAllowance, setHasAllowance] = useState(false)

  const estimatedAllowance = useMemo(
    () =>
      BigNumber(amountTokenA)
        .multipliedBy(getPrecision(pair!.token_a))
        .decimalPlaces(0)
        .toFixed(),
    [amountTokenA, pair]
  )

  const onAllowanceClick = useCallback(() => {
    if (!BigNumber(estimatedAllowance).isGreaterThan(0)) return
    approveAllowance(estimatedAllowance)
  }, [approveAllowance, estimatedAllowance])

  const checkAllowance = useCallback(() => {
    getAllowance(pair?.token_a_address).then((currentAllowance) => {
      if (
        BigNumber(currentAllowance).isGreaterThanOrEqualTo(estimatedAllowance)
      ) {
        setHasAllowance(true)
      } else {
        if (hasAllowance) setHasAllowance(false)
      }
    })
  }, [getAllowance, pair, hasAllowance, estimatedAllowance])

  useEffect(() => {
    if (flow === ContractMethod.SELL) {
      checkAllowance()
    }
    // eslint-disable-next-line
  }, [flow])

  useInterval(
    () => {
      checkAllowance()
    },
    flow === ContractMethod.SELL ? 5000 : null
  )

  if (flow === ContractMethod.BUY) {
    return <BottomButton label={t('trade.submit')} onClick={onClick} />
  }

  return (
    <div className="EthereumPerformSwapButton">
      <BottomButton
        options={{ disabled: hasAllowance }}
        label={t('trade.approve', { token: pair!.token_a })}
        onClick={onAllowanceClick}
      />
      <BottomButton
        options={{ disabled: !hasAllowance }}
        label={t('trade.only-submit')}
        onClick={onClick}
      />
    </div>
  )
}
