import React, { useRef } from 'react'
import { useDetectOutsideClick } from '../../Utils/useDetectOutsideClick'
import { Description } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import './DocumentationDropdown.scss'

export const DocumentationDropdown = () => {
  const dropdownRef = useRef(null)
  const { t } = useTranslation()
  const { isActive, setIsActive } = useDetectOutsideClick(dropdownRef, false)
  const onClick = () => setIsActive(!isActive)

  return (
    <div className="DocumentationDropdown">
      <span onClick={onClick} className="DocumentationDropdown__trigger">
        <Description />
        {t('top-header.documentation')}{' '}
      </span>
      <nav
        ref={dropdownRef}
        className={`DocumentationDropdown__menu ${
          isActive ? 'active' : 'inactive'
        }`}
      >
        <ul>
          <li>
            <a
              href="https://medium.com/unifiprotocol/introducing-the-unifi-protocol-2ad67bc8435"
              target="_blank"
              rel="noopener noreferrer"
            >
              White Paper
            </a>
          </li>
          <li>
            <a
              href="https://unifiprotocol.com/litepaper"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lite Paper
            </a>
          </li>
          <li className="bordered">
            <a
              href="https://medium.com/unifiprotocol/unifi-protocol-passes-slowmist-audit-9694b55b77ba"
              target="_blank"
              rel="noopener noreferrer"
            >
              Audit Report
            </a>
          </li>
          <li className="header">Support</li>
          <li>
            <a
              href="https://unifiprotocol.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Desk
            </a>
          </li>
          {/* <li className="bordered" onClick={() => onLanguageChange('kr')}>
            Gitbook
          </li> */}
        </ul>
      </nav>
    </div>
  )
}
