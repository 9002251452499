import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useRecoilValue } from 'recoil'
import { Config } from '../../Config'
import {
  TrackedTransaction,
  TrackedTransactions
} from '../../Stores/Transactions/TrackedTransactions'
import { Emitter, EmitterAction } from '../../Utils/EventEmitter'

import './NotificationsListener.scss'

export const NotificationsListener = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const trackedNotifications = useRecoilValue(TrackedTransactions)

  const trackedTransactionNotification = useCallback(
    ({
      transactionHash
    }: {
      transactionHash: TrackedTransaction['transactionHash']
    }) => {
      const mTransaction = trackedNotifications.find(
        (t) => t.transactionHash === transactionHash
      )!
      addToast(
        <div>
          <div>
            {t(`notification.confirmed.${mTransaction.type}`, {
              token: mTransaction.description
            })}
          </div>
          <div className="notification-hash">
            <a
              href={getTransactionHref(transactionHash)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transactionHash}
            </a>
          </div>
        </div>,
        { appearance: 'info' }
      )
    },
    [addToast, t, trackedNotifications]
  )

  useEffect(() => {
    Emitter.on(
      EmitterAction.TRANSACTION_CONFIRMED,
      trackedTransactionNotification as any
    )
    return () => {
      Emitter.off(
        EmitterAction.TRANSACTION_CONFIRMED,
        trackedTransactionNotification
      )
    }
  }, [addToast, trackedTransactionNotification])

  return <></>
}

function getTransactionHref(hash: string) {
  return Config.transactionExplorer.replace('{{TRANSACTION_HASH}}', hash)
}
