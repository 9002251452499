import React from 'react'
import { useAdapter } from '../../Stores/Adapter/useAdapter'
import { NoConnected } from './NoConnected'

export const withAdapter = (Wrapped: React.FC<any>) => () => {
  const { isConnected } = useAdapter()

  if (!isConnected) {
    return <NoConnected />
  } else {
    return <Wrapped />
  }
}
