export default {
  tron: {
    blockchain: 'tron',
    blockchainToken: 'TRX',
    transactionExplorer:
      'https://tronscan.org/#/transaction/{{TRANSACTION_HASH}}',
    accountExplorer: 'https://tronscan.org/#/address/{{ADDRESS}}',
    migrateContract: {
      address: 'TFpXkAfSsMNpjdKyN1xjFFzUGhj7c7vgoN'
    },
    depositSeedContract: 'TYh8fBjqFJJ8sLq4jxbU6YeHYbDhNFtWrV',
    claimUP: 'TRB5Sb49WF48gLQZoXboejfZjSog3PMSEp',
    pairs: [],
    contracts: {
      TRX: {
        address: 'TRX',
        decimals: 6
      },
      UPtrx: {
        address: 'TJ93jQZibdB3sriHYb5nNwjgkPPAcFR7ty',
        decimals: 6
      },
      UP: {
        address: 'TJ93jQZibdB3sriHYb5nNwjgkPPAcFR7ty',
        decimals: 6
      },
      SEED: {
        address: 'TBwoSTyywvLrgjSgaatxrBhxt3DGpVuENh',
        decimals: 6
      }
    },
    globalPrecision: 1000000,
    globalPowerPrecision: 6,
    percentagePrecision: 100000
  },
  ontology: {
    blockchain: 'ontology',
    blockchainToken: 'ONTd',
    transactionExplorer:
      'https://explorer.ont.io/transaction/{{TRANSACTION_HASH}}',
    accountExplorer: 'https://explorer.ont.io/address/{{ADDRESS}}/ALL/20/1',
    migrateContract: {
      address: 'AeiGZZfiUQM7ubi3KcNN23xJc2nZ1eghdV',
      scriptHash: 'fb9c5b8b77a6f0ecf6ace96a2fc1b86b47220f18'
    },
    depositSeedContract: 'e428566daff18f8c5bb336ca21899c3c9195ffe0',
    claimUP: '9ea26dc4f097291f45142eb402e0a47ebf39b582',
    pairs: [],
    contracts: {
      ONT: {
        address: 'ONT',
        decimals: 9
      },
      ONG: {
        address: 'ONG',
        decimals: 18
      },
      UPont: {
        address: '25810fc676b1d3060a66cf004f6f759eadbee2a2',
        decimals: 9
      },
      UP: {
        address: '25810fc676b1d3060a66cf004f6f759eadbee2a2',
        decimals: 9
      },
      SEED: {
        address: '78b98deed62aa708eaf6de85843734ecdfb14c1b',
        decimals: 6
      }
    },
    globalPrecision: 1000000,
    globalPowerPrecision: 9,
    percentagePrecision: 100000
  },
  harmony: {
    blockchain: 'harmony',
    blockchainToken: 'ONE',
    transactionExplorer:
      'https://explorer.harmony.one/#/tx/{{TRANSACTION_HASH}}',
    accountExplorer: 'https://explorer.harmony.one/#/address/{{ADDRESS}}',
    migrateContract: {
      address: '0xECc8fa682F2f957E0c83c0AeFBA0B4e61BB9d26c'
    },
    depositSeedContract: '0x81611046b205450558aeb9460747266e62add70C',
    claimUP: '0x317364304730822e9F692C7EA806492dd5dFA11A',
    pairs: [],
    contracts: {
      ONE: {
        address: 'ONE',
        decimals: 18
      },
      UP: {
        address: '0xe3c46e5F7a2aD3f7e69c92FeB1c8a5d846Fa9B6f',
        decimals: 18
      },
      UPone: {
        address: '0xe3c46e5F7a2aD3f7e69c92FeB1c8a5d846Fa9B6f',
        decimals: 18
      },
      SEED: {
        address: '0x793DAC3Ec4969A5BEE684BcF4290d52feB8F51b4',
        decimals: 6
      }
    },
    globalPrecision: Math.pow(10, 18),
    globalPowerPrecision: 18,
    percentagePrecision: 100000
  },
  icon: {
    blockchain: 'icon',
    blockchainToken: 'ICX',
    transactionExplorer:
      'https://tracker.icon.foundation/transaction/{{TRANSACTION_HASH}}',
    accountExplorer: 'https://tracker.icon.foundation/address/{{ADDRESS}}',
    migrateContract: {
      address: 'cxc5de4efcaf4c05417434fa591910f1bf68867780'
    },
    depositSeedContract: 'cx636caea5cf5a336d33985ae12ae1839821a175a4',
    claimUP: 'cxc432c12e6c91f8a685ee6ff50a653c8a056875e4',
    pairs: [],
    contracts: {
      ICX: {
        address: 'ICX',
        decimals: 18
      },
      UP: {
        address: 'cxce2b188690dcdf21e4d0868cc9aee9b8ab8e822e',
        decimals: 18
      },
      UPicx: {
        address: 'cxce2b188690dcdf21e4d0868cc9aee9b8ab8e822e',
        decimals: 18
      },
      SEED: {
        address: 'cx0c1840514299d1be79543930b9d09d16aaeb9a69',
        decimals: 6
      }
    },
    globalPrecision: Math.pow(10, 18),
    globalPowerPrecision: 18,
    percentagePrecision: 100000
  },
  binance: {
    blockchain: 'binance',
    blockchainToken: 'BNB',
    transactionExplorer: 'https://bscscan.com/tx/{{TRANSACTION_HASH}}',
    accountExplorer: 'https://bscscan.com/address/{{ADDRESS}}',
    migrateContract: {
      address: '0x9115e4A160Cd5b985fB42D0aD55b920929526341'
    },
    depositSeedContract: '0xAE34f2Be009Dc016324126C51B37Ed9ba5cEAcb1',
    claimUP: '0x146BF47D71Faa13C910216CD3245871D58F531eA',
    pairs: [],
    contracts: {
      BNB: {
        address: 'BNB',
        decimals: 18
      },
      UP: {
        address: '0xb4E8D978bFf48c2D8FA241C0F323F71C1457CA81',
        decimals: 18
      },
      UPbnb: {
        address: '0xb4E8D978bFf48c2D8FA241C0F323F71C1457CA81',
        decimals: 18
      },
      SEED: {
        address: '0xA433A20A30670B56861Bab29bEE5c5F1C7AedF70',
        decimals: 18
      }
    },
    globalPrecision: Math.pow(10, 18),
    globalPowerPrecision: 18,
    percentagePrecision: 100000
  },
  ethereum: {
    blockchain: 'ethereum',
    blockchainToken: 'ETH',
    transactionExplorer: 'https://etherscan.io/tx/{{TRANSACTION_HASH}}',
    accountExplorer: 'https://etherscan.io/address/{{ADDRESS}}',
    migrateContract: {
      address: '0x2f8661a098833ec43bef9717b7609bf70ca605f4'
    },
    depositSeedContract: '',
    claimUP: '',
    pairs: [],
    contracts: {
      ETH: {
        address: 'ETH',
        decimals: 18
      },
      UP: {
        address: '0xb6c5C839ceF46082A2B51164E8Db649c121f147E',
        decimals: 18
      },
      UPeth: {
        address: '0xb6c5C839ceF46082A2B51164E8Db649c121f147E',
        decimals: 18
      }
    },
    globalPrecision: Math.pow(10, 18),
    globalPowerPrecision: 18,
    percentagePrecision: 100000
  },
  iotex: {
    blockchain: 'iotex',
    blockchainToken: 'IOTX',
    transactionExplorer: 'https://iotexscan.io/action/{{TRANSACTION_HASH}}',
    accountExplorer: 'https://iotexscan.io/address/{{ADDRESS}}',
    migrateContract: {
      address: 'io1vhhmal09xpecm495nu0ffw40za5n9c4s0ys97h'
    },
    depositSeedContract: '',
    claimUP: '',
    pairs: [],
    contracts: {
      IOTX: {
        address: 'IOTX',
        decimals: 18
      },
      UP: {
        address: 'io1j9u0fmy20lm0uz8gfrh2c0wmuxjl43cdmgva4a',
        decimals: 18
      },
      UPiotx: {
        address: 'io1j9u0fmy20lm0uz8gfrh2c0wmuxjl43cdmgva4a',
        decimals: 18
      }
    },
    globalPrecision: Math.pow(10, 18),
    globalPowerPrecision: 18,
    percentagePrecision: 100000
  }
}
