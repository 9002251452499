import { Pair, Contract, Farm } from '../Stores/Pools/Types'
import { ContractMethod } from './Contract'

type Balance = string

export type AdapterBalance = { name: string; balance: Balance }

export type ExecutionResponse = {
  success: boolean
  functionName: ContractMethod
  value: string
  hash: string
  params: ExecutionValueProps
}

export type ExecutionParams = {
  args: Array<string | number | undefined>
  callValue: string | number | undefined
}

export type ExecutionValueProps = Partial<ExecutionParams>

export interface IAdapter {
  isConnected(): boolean
  getAddress(): string
  getBalances(): Promise<AdapterBalance[]>

  connect(props: {
    pairs: Pair[]
    contracts: { [token: string]: Contract }
    farms: Farm[]
  }): Promise<IAdapter>

  logout(): Promise<void>

  execute(
    contractName: string,
    method: ContractMethod,
    values: ExecutionValueProps,
    isWrite: boolean
  ): Promise<ExecutionResponse>

  getBalanceOf(
    contractAddress: string,
    targetAddress?: string
  ): Promise<AdapterBalance>

  waitForTransaction?(transactionHash: string): Promise<'SUCCESS' | 'FAILED'>
}

export const NON_SUCCESS_RESPONSE = { success: false, value: '', hash: '' }

export const STABLE_PAIRS_ERC20 = ['USDT', 'USDC', 'BUSD']
