import { useCallback, useMemo } from 'react'
import { useAdapter } from './useAdapter'
import { ContractMethod } from '../../Adapters/Contract'
import { getPrecision } from 'Utils/Decimals'
import { BN, MAX_UINT256, toHex, isNaN } from 'Utils/BigNumber'
import { usePools } from '../Pools/usePools'
import { Config } from '../../Config'
import { useTrackedTransactions } from '../Transactions/useTrackedTransactions'
import { useRecoilValue } from 'recoil'
import { Parameters } from '../Parameters/Parameters'
import { NON_SUCCESS_RESPONSE } from '../../Adapters/IAdapter'

export const useContract = (contractAddress: string | undefined) => {
  const { unlimitedApproval } = useRecoilValue(Parameters)
  const { execute, adapter } = useAdapter()
  const { addTrackedTransaction } = useTrackedTransactions()
  const { pairs } = usePools()

  const contractDetail = useMemo(
    () => pairs.find((p) => p.contract_address === contractAddress),
    [contractAddress, pairs]
  )

  const getAllowance = useCallback(
    async (tokenAddress?: string) => {
      if (adapter && contractDetail && contractAddress) {
        const allowanceResponse = await execute(
          tokenAddress || contractDetail.token_a_address,
          ContractMethod.ALLOWANCE,
          { args: [adapter.getAddress(), contractAddress] }
        )
        return BN(
          isNaN(allowanceResponse.value) ? 0 : allowanceResponse.value
        ).toNumber()
      }
      return 0
    },
    [execute, adapter, contractAddress, contractDetail]
  )

  const getTokenNameByContractAddress = useCallback(
    (tokenAddress: string) => {
      const findContract = Object.keys(Config.contracts).find((token) => {
        const tDetail = Config.contracts[token as keyof typeof Config.contracts]
        return tDetail.address === tokenAddress
      })
      return findContract ?? contractDetail!.token_a
    },
    [contractDetail]
  )

  const approveAllowance = useCallback(
    async (amount: string | number, contractToken?: string) => {
      if (adapter && contractDetail && contractAddress) {
        const reducedContractToken =
          contractToken ?? contractDetail.token_a_address

        const num = toHex(
          unlimitedApproval
            ? BN(MAX_UINT256).multipliedBy(0.95).decimalPlaces(0).toFixed()
            : BN(amount).decimalPlaces(0).toNumber()
        )

        const approveResponse = await execute(
          reducedContractToken,
          ContractMethod.APPROVE,
          { args: [contractAddress, num] },
          true
        )

        if (!approveResponse.success) {
          return approveResponse
        }

        addTrackedTransaction({
          address: adapter!.getAddress(),
          type: 'APPROVAL',
          description: getTokenNameByContractAddress(reducedContractToken),
          transactionHash: approveResponse.hash,
          notified: false,
          status: 'PENDING'
        })

        return approveResponse
      }
      return NON_SUCCESS_RESPONSE
    },
    [
      addTrackedTransaction,
      execute,
      getTokenNameByContractAddress,
      contractAddress,
      adapter,
      contractDetail,
      unlimitedApproval
    ]
  )

  const deposit = useCallback(
    async (amount: string | number, price: string | number) => {
      if (adapter && contractDetail && contractAddress) {
        const num = toHex(BN(amount).decimalPlaces(0).toNumber())
        let depositResponse
        if (Config.blockchain === 'ethereum') {
          const maxTradingToken = toHex(
            BN(num)
              .dividedBy(price)
              .multipliedBy(1.05)
              .decimalPlaces(0)
              .toFixed()
          )
          depositResponse = await execute(
            contractAddress,
            ContractMethod.DEPOSIT_SUPPLY,
            { args: [maxTradingToken], callValue: num },
            true
          )
        } else {
          depositResponse = await execute(
            contractAddress,
            ContractMethod.DEPOSIT_SUPPLY,
            { callValue: num },
            true
          )
        }

        addTrackedTransaction({
          address: adapter!.getAddress(),
          type: 'DEPOSIT',
          description: '',
          transactionHash: depositResponse.hash,
          notified: false,
          status: 'PENDING'
        })

        return depositResponse
      }
      return false
    },
    [adapter, execute, addTrackedTransaction, contractAddress, contractDetail]
  )

  const withdraw = useCallback(
    async (
      amount: string | number,
      redeemTradingToken: string | number,
      redeemBaseToken: string | number
    ) => {
      if (adapter && contractDetail && contractAddress) {
        const num = toHex(BN(amount).decimalPlaces(0).toNumber())
        let withdrawResponse

        if (Config.blockchain === 'ethereum') {
          const minTradingToken = toHex(
            BN(redeemTradingToken)
              .multipliedBy(0.995)
              .decimalPlaces(0)
              .toFixed()
          )
          const minBaseToken = toHex(
            BN(redeemBaseToken).multipliedBy(0.995).decimalPlaces(0).toFixed()
          )
          withdrawResponse = await execute(
            contractAddress,
            ContractMethod.WITHDRAW_SUPPLY,
            { args: [num, minTradingToken, minBaseToken] },
            true
          )
        } else {
          withdrawResponse = await execute(
            contractAddress,
            ContractMethod.WITHDRAW_SUPPLY,
            { args: [num] },
            true
          )
        }

        addTrackedTransaction({
          address: adapter!.getAddress(),
          type: 'WITHDRAW',
          description: '',
          transactionHash: withdrawResponse.hash,
          notified: false,
          status: 'PENDING'
        })

        return withdrawResponse
      }
      return false
    },
    [adapter, execute, addTrackedTransaction, contractAddress, contractDetail]
  )

  const sell = useCallback(
    async (amount: string | number, estimatedReturn: string) => {
      if (adapter && contractDetail && contractAddress) {
        const num = toHex(
          BN(amount)
            .multipliedBy(getPrecision(contractDetail.token_a))
            .decimalPlaces(0)
            .toNumber()
        )
        let sellResponse

        if (Config.blockchain === 'ethereum') {
          const minBaseAmount = toHex(
            BN(estimatedReturn).multipliedBy(0.995).decimalPlaces(0).toFixed()
          )
          sellResponse = await execute(
            contractAddress,
            ContractMethod.SELL,
            { args: [num, minBaseAmount] },
            true
          )
        } else {
          sellResponse = await execute(
            contractAddress,
            ContractMethod.SELL,
            { args: [num] },
            true
          )
        }

        addTrackedTransaction({
          address: adapter.getAddress(),
          type: 'SWAP',
          description: '',
          transactionHash: sellResponse.hash,
          notified: false,
          status: 'PENDING'
        })

        return sellResponse
      }
      return false
    },
    [adapter, execute, addTrackedTransaction, contractAddress, contractDetail]
  )

  const buy = useCallback(
    async (amount: string | number, estimatedReturn: string) => {
      if (adapter && contractDetail && contractAddress) {
        const num = toHex(
          BN(amount)
            .multipliedBy(getPrecision(contractDetail.token_b))
            .decimalPlaces(0)
            .toNumber()
        )
        let buyResponse

        if (Config.blockchain === 'ethereum') {
          const minTradingAmount = toHex(
            BN(estimatedReturn).multipliedBy(0.995).decimalPlaces(0).toFixed()
          )
          buyResponse = await execute(
            contractAddress,
            ContractMethod.BUY,
            { args: [adapter.getAddress(), minTradingAmount], callValue: num },
            true
          )
        } else {
          buyResponse = await execute(
            contractAddress,
            ContractMethod.BUY,
            { args: [adapter.getAddress()], callValue: num },
            true
          )
        }

        addTrackedTransaction({
          address: adapter.getAddress(),
          type: 'SWAP',
          description: '',
          transactionHash: buyResponse.hash,
          notified: false,
          status: 'PENDING'
        })

        return buyResponse
      }
      return false
    },
    [adapter, execute, contractAddress, addTrackedTransaction, contractDetail]
  )

  const claim = useCallback(async () => {
    if (adapter && contractAddress) {
      return execute(contractAddress, ContractMethod.CLAIM_FEE, {}, true)
    }
    return NON_SUCCESS_RESPONSE
  }, [execute, adapter, contractAddress])

  return {
    getAllowance,
    approveAllowance,
    deposit,
    withdraw,
    buy,
    sell,
    claim
  }
}
