import React from 'react'
import TRON from 'Assets/Blockchains/TRON.png'
import ONTOLOGY from 'Assets/Blockchains/ONTOLOGY.png'
import HARMONY from 'Assets/Blockchains/HARMONY.png'
import BINANCE from 'Assets/Blockchains/BINANCE.png'
import ETHEREUM from 'Assets/Blockchains/ETHEREUM.png'
import ICON from 'Assets/Blockchains/ICON.png'
import IOTEX from 'Assets/Blockchains/IOTX.png'
import { useTranslation } from 'react-i18next'
import { Config } from '../../Config'

import './BlockchainSelector.scss'

const isAlpha = /(alpha|localhost)/.test(window.location.href)

const BLOCKCHAIN_LINKS: {
  [blockchain: string]: { prod: string; alpha: string }
} = {
  tron: {
    prod: 'https://tron.unifiprotocol.com',
    alpha: 'https://alpha.unifiprotocol.com'
  },
  ontology: {
    prod: 'https://ontology.unifiprotocol.com',
    alpha: 'https://alpha-ontology.unifiprotocol.com'
  },
  harmony: {
    prod: 'https://harmony.unifiprotocol.com',
    alpha: 'https://alpha-harmony.unifiprotocol.com'
  },
  binance: {
    prod: 'https://binance.unifiprotocol.com',
    alpha: 'https://alpha-bsc.unifiprotocol.com'
  },
  icon: {
    prod: 'https://icon.unifiprotocol.com',
    alpha: 'https://alpha-icon.unifiprotocol.com'
  },
  ethereum: {
    prod: 'https://ethereum.unifiprotocol.com',
    alpha: 'https://alpha-ethereum.unifiprotocol.com'
  },
  iotex: {
    prod: 'https://iotex.unifiprotocol.com',
    alpha: 'https://alpha-iotex.unifiprotocol.com'
  }
}

function getBlockchainLink(blockchain: string) {
  return BLOCKCHAIN_LINKS[blockchain][isAlpha ? 'alpha' : 'prod']
}

export const BlockchainSelector = () => {
  const { t } = useTranslation()

  return (
    <div className="BlockchainSelector">
      <span className="BlockchainSelector__title">
        {t('blockchain-selector.title')}
      </span>
      <span
        className={`BlockchainSelector__blockchain-item tron ${
          Config.blockchain === 'tron' ? 'active' : 'no-active'
        }`}
        onClick={() => {
          window.location.href = getBlockchainLink('tron')
        }}
      >
        <img src={TRON} alt="tron" />
      </span>
      <span
        className={`BlockchainSelector__blockchain-item ontology ${
          Config.blockchain === 'ontology' ? 'active' : 'no-active'
        }`}
        onClick={() => {
          window.location.href = getBlockchainLink('ontology')
        }}
      >
        <img src={ONTOLOGY} alt="ONTOLOGY" />
      </span>
      <span
        className={`BlockchainSelector__blockchain-item harmony ${
          Config.blockchain === 'harmony' ? 'active' : 'no-active'
        }`}
        onClick={() => {
          window.location.href = getBlockchainLink('harmony')
        }}
      >
        <img src={HARMONY} alt="HARMONY" />
      </span>
      <span
        className={`BlockchainSelector__blockchain-item binance ${
          Config.blockchain === 'binance' ? 'active' : 'no-active'
        }`}
        onClick={() => {
          window.location.href = getBlockchainLink('binance')
        }}
      >
        <img src={BINANCE} alt="BINANCE" />
      </span>
      <span
        className={`BlockchainSelector__blockchain-item ethereum ${
          Config.blockchain === 'ethereum' ? 'active' : 'no-active'
        }`}
        onClick={() => {
          window.location.href = getBlockchainLink('ethereum')
        }}
      >
        <img src={ETHEREUM} alt="ETHEREUM" />
      </span>
      <span
        className={`BlockchainSelector__blockchain-item icon ${
          Config.blockchain === 'icon' ? 'active' : 'no-active'
        }`}
        onClick={() => {
          window.location.href = getBlockchainLink('icon')
        }}
      >
        <img src={ICON} alt="ICON" />
      </span>
      <span
        className={`BlockchainSelector__blockchain-item iotex ${
          Config.blockchain === 'iotex' ? 'active' : 'no-active'
        }`}
        onClick={() => {
          window.location.href = getBlockchainLink('iotex')
        }}
      >
        <img src={IOTEX} alt="iotex" />
      </span>
    </div>
  )
}
