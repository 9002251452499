import { atom } from 'recoil'

type LoadingState = {
  loading: number
  totalRequests: number
}

export const Loading = atom<LoadingState>({
  key: 'loading',
  default: {
    loading: 0,
    totalRequests: 0
  }
})
