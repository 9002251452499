import { useAdapter } from '../Adapter/useAdapter'
import { Emitter, EmitterAction } from 'Utils/EventEmitter'
import { AdapterBalance } from '../../Adapters/IAdapter'
import { useRecoilState } from 'recoil'
import { Balances } from './Balances'
import { useCallback } from 'react'

export const useBalances = () => {
  const [{ balances }, setBalancesState] = useRecoilState(Balances)
  const { adapter, isConnected } = useAdapter()

  const updateBalances = async () => {
    if (adapter && isConnected) {
      await adapter.getBalances()
      setTimeout(() => {
        Emitter.emit(EmitterAction.REFRESH_BALANCES)
      }, 2000)
    }
  }

  const updateBalance = async (balances: AdapterBalance[]) => {
    balances.forEach((b) => {
      setBalancesState(({ balances }) => {
        const currentBalance = balances.find((bc) => bc.name === b.name)
        if (currentBalance) {
          currentBalance.balance = b.balance
        } else {
          balances.push(b)
        }
        return { balances }
      })
    })
  }

  const getBalanceByToken = useCallback(
    (token: string) => {
      const tokenBalance = balances.find(
        (t) => t.name.toLowerCase() === token.toLowerCase()
      )
      return tokenBalance?.balance || '0'
    },
    [balances]
  )

  return {
    updateBalance,
    updateBalances,
    getBalanceByToken,
    balances
  }
}
