import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import Container from 'Views/Container'
import { Bg } from 'Components/Bg'
import Updater from 'Components/Updater'
import { ToastProvider } from 'react-toast-notifications'
import i18next from './i18n'
import TopHeader from './Components/TopHeader'
import { RecoilRoot } from 'recoil'
import { NotificationsListener } from './Components/NotificationsListener'
// import ErrorBoundary from './Components/ErrorBoundary'

import './App.scss'

function App() {
  return (
    <div className="App">
      <Bg />
      {/* <ErrorBoundary> */}
      <RecoilRoot>
        <ToastProvider autoDismissTimeout={3000} autoDismiss={true}>
          <I18nextProvider i18n={i18next}>
            <TopHeader />
            <Router>
              <Updater>
                <Container />
              </Updater>
            </Router>
            <NotificationsListener />
          </I18nextProvider>
        </ToastProvider>
      </RecoilRoot>
      {/* </ErrorBoundary> */}
    </div>
  )
}

export default App
