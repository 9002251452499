import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useAdapter } from 'Stores/Adapter/useAdapter'
import { Contract, ContractMethod } from 'Adapters/Contract'
import { useInterval } from 'Utils/useInterval'
import { areNumbers, isNaN, BN, truncate, toHex } from 'Utils/BigNumber'
import { useLoading } from 'Stores/Loading/useLoading'
import { useContract } from 'Stores/Adapter/useContract'
import { useBalances } from 'Stores/Balances/useBalances'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { getDecimals, getPrecision } from 'Utils/Decimals'
import { Icon } from '../../Components/Icon'
import { usePools } from '../Pools/usePools'
import { Config } from '../../Config'
import { useRecoilState } from 'recoil'
import { Swap, SwapState } from './Swap'
import { useTranslation } from 'react-i18next'
import { throttle } from '../../Utils/Throttle'

export const useSwap = () => {
  const [
    { tokenA, tokenB, amountTokenA, amountTokenB },
    setSwapState
  ] = useRecoilState(Swap)
  const [flow, setFlow] = useState<ContractMethod.BUY | ContractMethod.SELL>(
    ContractMethod.BUY
  )
  const [lastSideInput, setLastSideInput] = useState<'TokenA' | 'TokenB'>(
    'TokenA'
  )
  const { adapter, isConnected } = useAdapter()
  const { isLoading } = useLoading()
  const { getBalanceByToken } = useBalances()
  const { addExpectedTransaction } = useTransactions()
  const { pairs, contracts } = usePools()
  const { t } = useTranslation()

  // SmartContract Variables
  const [maxTransaction, setMaxTransaction] = useState('0')
  const [tradeFeeBase, setTradeFeeBase] = useState('0')
  const [price, setPrice] = useState('0')
  const [estimatedReturn, setEstimatedReturn] = useState('0')
  const [contractState, setContractState] = useState<'1' | '0'>('1')

  const selectedPair = useMemo(() => {
    const selected = pairs.find((p) => {
      return tokenA === p.token_a && tokenB === p.token_b
    })
    if (selected) {
      setFlow(ContractMethod.SELL)
      return selected
    }
    const invertedSelected = pairs.find((p) => {
      return tokenA === p.token_b && tokenB === p.token_a
    })
    if (invertedSelected) {
      setFlow(ContractMethod.BUY)
      return invertedSelected
    }
    return undefined
  }, [tokenB, tokenA, pairs])

  const tokenAContract = useMemo(() => {
    const tkn = tokenA as Contract
    if (!contracts[tkn]) return ''
    const { address } = contracts[tkn]
    return address
  }, [tokenA, contracts])

  const isSwapReady = useMemo(() => {
    if (contractState === '0') return false
    const amount = [amountTokenB, amountTokenA]
    const areMountsOk = areNumbers(amount) && !!selectedPair
    const isHigherThanBalancesA = BN(
      getBalanceByToken(tokenAContract)
    ).isGreaterThanOrEqualTo(amountTokenA)
    const isLesserThanMaxTransaction = BN(amountTokenA).isLessThanOrEqualTo(
      maxTransaction
    )
    const amountIsNotZero = BN(amountTokenA).isGreaterThan(0)
    return (
      areMountsOk &&
      isHigherThanBalancesA &&
      isLesserThanMaxTransaction &&
      amountIsNotZero
    )
  }, [
    getBalanceByToken,
    contractState,
    selectedPair,
    amountTokenA,
    amountTokenB,
    maxTransaction,
    tokenAContract
  ])

  const selectablePairs = useMemo(() => {
    const selectableTokenA = pairs.map((x) => x.token_a)
    const selectableTokenB = pairs.map((x) => x.token_b)
    const selectableTokens = Array.from(
      new Set([...selectableTokenA, ...selectableTokenB])
    )
    selectableTokens.sort()
    return selectableTokens.map((p) => ({
      label: (
        <>
          <Icon icon={p} /> {p}
        </>
      ),
      value: p
    }))
  }, [pairs])

  const toAvailablePairs = useMemo(() => {
    if (!tokenA) return selectablePairs
    const candidatesBuy = pairs
      .filter((p) => p.token_a === tokenA)
      .map((p) => selectablePairs.find((x) => x.value === p.token_b)!)
    const candidatesSell = pairs
      .filter((p) => p.token_b === tokenA)
      .map((p) => selectablePairs.find((x) => x.value === p.token_a)!)
    return [...candidatesBuy, ...candidatesSell]
  }, [tokenA, selectablePairs, pairs])

  const submitMessage = useMemo(() => {
    if (isSwapReady) return t('trade.submit')
    if (contractState === '0') return t('trade.submit.pair-disabled')
    if (
      BN(amountTokenA).isGreaterThanOrEqualTo(getBalanceByToken(tokenAContract))
    ) {
      return t('trade.submit.unsufficient-balances')
    }
    if (BN(amountTokenA).isGreaterThan(maxTransaction)) {
      return t('trade.submit.amount-gt-max-trade')
    }
    return t('trade.submit.enter-amount')
  }, [
    t,
    getBalanceByToken,
    contractState,
    amountTokenA,
    maxTransaction,
    tokenAContract,
    isSwapReady
  ])

  const { buy, sell, approveAllowance, getAllowance } = useContract(
    selectedPair?.contract_address
  )

  const updateSwap = useCallback(
    (state: Partial<SwapState>) => {
      setSwapState((s) => ({ ...s, ...state }))
    },
    [setSwapState]
  )

  // UPDATE METHODS
  const updateTradeFee = useCallback(async () => {
    if (selectedPair && adapter) {
      const mTx = await adapter.execute(
        selectedPair.contract_address,
        ContractMethod.GET_FEE,
        {},
        false
      )
      const value = BN(mTx.value || '0').toFixed()
      return setTradeFeeBase(value)
    }
    setTradeFeeBase('0')
  }, [selectedPair, adapter])

  const updateContractState = useCallback(async () => {
    if (selectedPair && adapter) {
      const mTx = await adapter.execute(
        selectedPair.contract_address,
        ContractMethod.GET_STATE,
        {},
        false
      )
      const value = mTx.value as '1' | '0'
      return setContractState(value)
    }
  }, [selectedPair, adapter])

  const tradeFee = useMemo(() => {
    const multiplier = flow === ContractMethod.BUY ? amountTokenA : amountTokenB
    return BN(tradeFeeBase)
      .multipliedBy(multiplier || 0)
      .dividedBy(Config.percentagePrecision)
      .toFixed()
  }, [tradeFeeBase, amountTokenA, amountTokenB, flow])

  const updateMaxTransaction = useCallback(async () => {
    if (selectedPair && adapter) {
      const mTx = await adapter.execute(
        selectedPair.contract_address,
        ContractMethod.GET_MAX_TRANSACTION,
        {},
        false
      )
      const value = BN(mTx.value)
        .dividedBy(getPrecision(selectedPair.token_b))
        .toFixed()

      if (flow === ContractMethod.SELL) {
        const maxTransactionSell = BN(value)
          .dividedBy(BN(price).dividedBy(getPrecision(selectedPair.token_b)))
          .toFixed()
        return setMaxTransaction(maxTransactionSell)
      }

      return setMaxTransaction(value)
    }
    setMaxTransaction('0')
  }, [selectedPair, adapter, flow, price])

  const updatePrice = useCallback(async () => {
    if (selectedPair && adapter) {
      const mTx = await adapter.execute(
        selectedPair.contract_address,
        ContractMethod.GET_PRICE,
        {},
        false
      )
      const value = BN(mTx.value).toFixed()
      return setPrice(value)
    }
    setPrice('0')
  }, [selectedPair, adapter])

  // ON EVENT METHODS

  const performSwap = useCallback(async () => {
    if (isConnected && isSwapReady && !isLoading && selectedPair) {
      const method = flow === ContractMethod.BUY ? buy : sell

      // ETHEREUM HAS AN APPROVAL PROCESS PERFORMED IN AN ISOLATED STEP
      if (!['ethereum'].includes(Config.blockchain)) {
        const allowanceNeed = BN(amountTokenA)
          .multipliedBy(getPrecision(tokenA))
          .multipliedBy(BN(tradeFee).plus(1))
          .decimalPlaces(0)
          .toFixed()

        // CHECK ALLOWANCE
        const currentAllowance = await getAllowance(tokenAContract)

        if (BN(currentAllowance).isLessThan(allowanceNeed)) {
          const allowanceResponse = await approveAllowance(
            allowanceNeed,
            tokenAContract
          )
          if (!allowanceResponse.success) {
            return false
          }
        }
      }

      const transaction = await method(amountTokenA, estimatedReturn)

      if (transaction) {
        addExpectedTransaction({
          transaction,
          movements: {
            sent: {
              name: tokenA,
              value: BN(amountTokenA).toFixed()
            },
            received: {
              name: tokenB,
              value: BN(amountTokenB).toFixed()
            }
          }
        })
      }

      return transaction
    }
  }, [
    addExpectedTransaction,
    approveAllowance,
    buy,
    getAllowance,
    sell,
    estimatedReturn,
    amountTokenB,
    tokenA,
    tokenB,
    isSwapReady,
    selectedPair,
    amountTokenA,
    flow,
    isLoading,
    tradeFee,
    tokenAContract,
    isConnected
  ])

  const onInputChange = (inputName: 'TokenA' | 'TokenB') => (value: string) => {
    if (!selectedPair) return

    if (isNaN(value) && value !== '') return
    const val = value || '0'

    if (inputName === 'TokenA') {
      updateSwap({
        amountTokenA: val
      })
    } else {
      updateSwap({
        amountTokenB: val
      })
    }
    setLastSideInput(inputName)
  }

  // COMPUTED VARIABLES

  const getEstimatedReturn = useCallback(
    throttle(async () => {
      if (!adapter || !selectedPair || lastSideInput === 'TokenB') return
      const method =
        flow === ContractMethod.BUY
          ? ContractMethod.GET_ESTIMATED_BUY_RECEIVE_AMOUNT
          : ContractMethod.GET_ESTIMATED_SELL_RECEIVE_AMOUNT

      const precision =
        flow === ContractMethod.BUY
          ? getPrecision(selectedPair.token_b)
          : getPrecision(selectedPair.token_a)

      const amount = toHex(
        truncate(BN(amountTokenA).multipliedBy(precision).toFixed())
      )

      if (isNaN(amount)) return

      const mTx = await adapter.execute(
        selectedPair.contract_address,
        method,
        {
          args: [amount]
        },
        false
      )
      const value = BN(mTx.value).dividedBy(getPrecision(tokenB)).toFixed()

      updateSwap({ amountTokenB: value })
      setEstimatedReturn(value)
    }),
    [
      updateSwap,
      amountTokenA,
      flow,
      adapter,
      selectedPair,
      lastSideInput,
      tokenB
    ]
  )

  const tokenAFactorized = useMemo(() => {
    if (!tokenA || !amountTokenA) return '0'
    return BN(amountTokenA).multipliedBy(getPrecision(tokenA))
  }, [amountTokenA, tokenA])

  const fixedPrice = useMemo(() => {
    if (!selectedPair) return '0'
    if (flow === ContractMethod.BUY) {
      return BN(1)
        .dividedBy(BN(price).dividedBy(getPrecision(selectedPair.token_b)))
        .decimalPlaces(getDecimals(tokenB))
        .toFixed()
    } else {
      return BN(price)
        .dividedBy(getPrecision(selectedPair.token_b))
        .decimalPlaces(getDecimals(tokenB))
        .toFixed()
    }
  }, [selectedPair, flow, price, tokenB])

  const estimatedPrice = useMemo(() => {
    if (!selectedPair) return '0'
    if (
      lastSideInput === 'TokenB' ||
      !estimatedReturn ||
      !amountTokenA ||
      BN(amountTokenA).isZero()
    ) {
      return fixedPrice
    }
    return BN(estimatedReturn).dividedBy(amountTokenA).toFixed()
  }, [estimatedReturn, fixedPrice, amountTokenA, selectedPair, lastSideInput])

  const againstPrice = useMemo(() => {
    return BN(1)
      .dividedBy(estimatedPrice)
      .decimalPlaces(getDecimals(flow === ContractMethod.BUY ? tokenB : tokenA))
      .toFixed()
  }, [estimatedPrice, flow, tokenA, tokenB])

  const slippage = useMemo(() => {
    const factorizedEstimatedReturn = BN(estimatedReturn).multipliedBy(
      getPrecision(tokenB)
    )
    if (!tokenAFactorized) return '0'
    let result
    if (flow === ContractMethod.BUY) {
      const estimatedReturnPrice = BN(tokenAFactorized)
        .dividedBy(factorizedEstimatedReturn)
        .multipliedBy(getPrecision(tokenB))
      result = BN(estimatedReturnPrice)
        .minus(BN(price))
        .dividedBy(price)
        .multipliedBy(100)
    } else {
      const estimatedReturnPrice = BN(factorizedEstimatedReturn)
        .dividedBy(tokenAFactorized)
        .multipliedBy(getPrecision(tokenA))
      result = BN(price)
        .minus(BN(estimatedReturnPrice))
        .dividedBy(estimatedReturnPrice)
        .multipliedBy(100)
    }
    return result.isNaN()
      ? '0'
      : result
          .minus(BN(tradeFeeBase).dividedBy(1000))
          .decimalPlaces(2)
          .abs()
          .toFixed()
  }, [
    tokenAFactorized,
    price,
    flow,
    estimatedReturn,
    tokenB,
    tokenA,
    tradeFeeBase
  ])

  const getEstimatedInput = useCallback(() => {
    if (lastSideInput === 'TokenA' || !fixedPrice) return
    const estimatedInput = BN(amountTokenB).dividedBy(fixedPrice).toFixed()
    if (isNaN(estimatedInput)) return
    updateSwap({
      amountTokenA: estimatedInput
    })
  }, [updateSwap, amountTokenB, fixedPrice, lastSideInput])

  // EFFECTS

  useEffect(() => {
    getEstimatedReturn()
  }, [getEstimatedReturn, amountTokenA, lastSideInput])

  useEffect(() => {
    getEstimatedInput()
  }, [getEstimatedInput, amountTokenB])

  useEffect(() => {
    updateContractState()
    updatePrice()
    updateMaxTransaction()
    updateTradeFee()
    // eslint-disable-next-line
  }, [updatePrice, updateMaxTransaction, updateTradeFee, selectedPair])

  useInterval(
    () => {
      updateContractState()
      updateMaxTransaction()
      updateTradeFee()
      updatePrice()
      getEstimatedReturn()
    },
    adapter ? 3000 : null
  )

  return {
    updateSwap,
    performSwap,
    onInputChange,
    maxTransaction,
    selectedPair,
    tokenA,
    tokenB,
    amountTokenA,
    amountTokenB,
    lastSideInput,
    tradeFee,
    isSwapReady,
    flow,
    slippage,
    againstPrice,
    price: estimatedPrice,
    toAvailablePairs,
    pairs: selectablePairs,
    submitMessage
  }
}
