import React, { useCallback } from 'react'
// import { useToasts } from 'react-toast-notifications'
import { BigNumber as BN } from 'Utils/BigNumber'
import Button from 'Components/Button'
import { useTranslation } from 'react-i18next'

import './Input.scss'

const Input: React.FC<{
  placeholder?: string
  onChange?: (value: string) => void
  balance?: string
  value?: string
  max?: boolean
  customMax?: string
  disabled?: boolean
  decimals?: number
  exactMax?: boolean
  estimated?: boolean
}> = ({
  onChange,
  placeholder,
  balance,
  value,
  max,
  disabled,
  exactMax,
  customMax,
  estimated
}) => {
  // const { addToast } = useToasts()
  const { t } = useTranslation()

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange(value)
  }

  const onMaxclick = useCallback(() => {
    if (max && balance && BN(balance).isGreaterThan(0) && onChange) {
      const reducedBalance = customMax ?? balance
      onChange(
        BN(reducedBalance)
          .multipliedBy(exactMax ? 0.9999 : 0.9998)
          .toFixed()
      )
    }
  }, [balance, max, onChange, exactMax, customMax])

  return (
    <div className="Input-Container">
      {balance !== undefined && (
        <div className="Input-Container__balance">
          {t('input.balance')}: {balance}
        </div>
      )}
      <div className="Input-Container__wrapper">
        <span
          className={`Input-Container__wrapper__estimated ${
            estimated ? 'active' : 'no-active'
          }`}
        >
          ≈
        </span>
        <input
          type="text"
          className="Input"
          placeholder={placeholder}
          onChange={(e) => onChangeHandler(e)}
          value={value}
          disabled={disabled}
        />
        {balance !== undefined && max !== undefined && (
          <span className="Input-Container__wrapper__max">
            <Button onClick={onMaxclick}>{t('input.max')}</Button>
          </span>
        )}
      </div>
    </div>
  )
}

export default Input
