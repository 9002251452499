import React from 'react'
import { Config } from '../../Config'
import { IconPoolJoin } from '../../Views/IconPoolJoin'
import { PoolJoin } from '../../Views/PoolJoin'

const Views: { [k: string]: React.FC } = {
  icon: IconPoolJoin
}

export const PoolJoinWrapper = () => {
  if (Views[Config.blockchain]) {
    const View = Views[Config.blockchain]
    return <View />
  }

  return <PoolJoin />
}
