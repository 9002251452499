import { atom } from 'recoil'
import { TransactionsState } from './Types'

export const Transactions = atom<TransactionsState>({
  key: 'transactions',
  default: {
    transactions: [],
    expectedTransactions: []
  }
})
