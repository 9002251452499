import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Route } from 'react-router-dom'
import Header from 'Components/Header'
import { Swap } from 'Views/Swap'
import { Liquidity } from 'Views/Liquidity'
import { Up } from 'Views/Up'
import { UpSuccesful } from 'Views/UpSuccessful'
import { SwapSuccessful } from 'Views/SwapSuccessful'
import { Pool } from 'Views/Pool'
import { DepositSuccessful } from 'Views/DepositSuccessful'
import { RemovePool } from 'Views/RemovePool'
import { RemovePoolSuccessful } from 'Views/RemovePoolSuccessful'
import { RedeemPool } from 'Views/RedeemPool'
import { ClaimRewards } from 'Views/ClaimRewards'
import { ClaimSuccessful } from 'Views/ClaimSuccesful'
import { Failed } from 'Views/Failed'
import Loading from 'Components/Loading'
import { PoolJoinWrapper } from '../../Components/PoolJoinWrapper'
import { UpHome } from '../UpHome'
import { WithdrawCreditsSuccessful } from '../WithdrawCreditsSuccessful'
import { UpMigrateSuccesful } from '../UpMigrateSuccessful'
import { withAdapter } from '../../Components/WithAdapter'
// import { MigrateUp } from '../MigrateUp'
// import withWarning from '../../Components/WarningWrapper'
import { Farms } from '../Farms'
import { DepositSeed } from '../DepositSeed'
import { DepositSeedSuccessful } from '../DepositSeedSuccessful'
import { ClaimMigrationUP } from '../ClaimMigrationUP'

import './Container.scss'

const successRoutes = [
  {
    path: '/liquidity/withdraw-credits/:contractAddress/success/:txHash',
    Component: WithdrawCreditsSuccessful // ICON
  },
  {
    path: '/liquidity/remove-pool/:contractAddress/success',
    Component: RemovePoolSuccessful
  },
  {
    path: '/liquidity/remove-pool/:contractAddress/success/:txHash',
    Component: RemovePoolSuccessful
  },
  {
    path: '/liquidity/pool/join/:contractAddress/success',
    Component: DepositSuccessful
  },
  {
    path: '/liquidity/pool/join/:contractAddress/success/:txHash',
    Component: DepositSuccessful
  },
  { path: '/liquidity/claim-rewards/success', Component: ClaimSuccessful },
  {
    path: '/liquidity/claim-rewards/success/:txHash',
    Component: ClaimSuccessful
  },
  { path: '/trade/success', Component: SwapSuccessful },
  { path: '/trade/success/:txHash', Component: SwapSuccessful },
  { path: '/claim-up', Component: ClaimMigrationUP },
  { path: '/up/success', Component: UpSuccesful },
  { path: '/up/success/:txHash', Component: UpSuccesful },
  { path: '/up-migrate/success', Component: UpMigrateSuccesful },
  { path: '/up-migrate/success/:txHash', Component: UpMigrateSuccesful },
  { path: '/seed/success/:txHash', Component: DepositSeedSuccessful },
  { path: '/seed/success', Component: DepositSeedSuccessful }
]

const routes = [
  { path: '/', Component: Swap },
  { path: '/trade', Component: Swap },
  { path: '/farms', Component: Farms },
  { path: '/up', Component: Up },
  { path: '/up/redeem', Component: Up },
  { path: '/up/seed', Component: DepositSeed },
  // { path: '/up/migrate', Component: withWarning(MigrateUp) },
  { path: '/liquidity', Component: Liquidity },
  { path: '/liquidity/claim-rewards', Component: withAdapter(ClaimRewards) },
  { path: '/liquidity/pool', Component: Pool },
  {
    path: '/liquidity/pool/join/:contractAddress',
    Component: withAdapter(PoolJoinWrapper)
  },
  { path: '/liquidity/remove-pool', Component: withAdapter(RemovePool) },
  {
    path: '/liquidity/remove-pool/:contractAddress',
    Component: withAdapter(RedeemPool)
  },
  { path: '/failed', Component: Failed },
  ...successRoutes
]

const Container: React.FC = () => {
  return (
    <div className="Container">
      <Loading />
      <div className="Container__content">
        <div className="Container__content__nav">
          <Header />
        </div>
        <div className="Container__content__wrapper Wrapper">
          {routes.map(({ path, Component }) => (
            <Route path={path} exact key={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="slide"
                  unmountOnExit
                >
                  <Component />
                </CSSTransition>
              )}
            </Route>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Container
