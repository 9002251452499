import { atom } from 'recoil'
import { Config } from '../../Config'

export type SwapState = {
  tokenA: string
  tokenB: string
  amountTokenA: string
  amountTokenB: string
}

export const Swap = atom<SwapState>({
  key: 'swapState',
  default: {
    amountTokenA: '',
    amountTokenB: '',
    tokenA: Config.blockchainToken,
    tokenB: ''
  }
})
