import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Table, TableRow, TableHeader } from 'Components/Table'
import { Add } from '@material-ui/icons'
import Input from 'Components/Input'
import Button from 'Components/Button'
import { useAdapter } from 'Stores/Adapter/useAdapter'
import { ContractMethod } from 'Adapters/Contract'
import { useBalances } from 'Stores/Balances/useBalances'
import {
  BigNumber as BN,
  localiseNumber,
  isNumberAndNonZero,
  truncateDecimals,
  isNaN,
  toBNFixed
} from 'Utils/BigNumber'
import { useContract } from 'Stores/Adapter/useContract'
import { useInterval } from 'Utils/useInterval'
import { useLoading } from 'Stores/Loading/useLoading'
import { Back } from 'Components/Back'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { useToasts } from 'react-toast-notifications'
import { getDecimals, getPrecision } from 'Utils/Decimals'
import { useTranslation } from 'react-i18next'
import { usePools } from '../../Stores/Pools/usePools'
import { Config } from '../../Config'
import { PoolJoinButton } from '../../Components/PoolJoinButton'

import './PoolJoin.scss'

const PoolJoin: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { contractAddress } = useParams<{ contractAddress: string }>()
  const { addToast } = useToasts()
  const { approveAllowance, getAllowance, deposit } = useContract(
    contractAddress
  )
  const { execute, adapter } = useAdapter()
  const { getBalanceByToken } = useBalances()
  const { isLoading } = useLoading()
  const { addExpectedTransaction } = useTransactions()
  const [tokenAInput, setTokenAInput] = useState('')
  const [tokenBInput, setTokenBInput] = useState('')
  const [pairDetail, setPairDetail] = useState({
    name: '',
    token_a: '',
    token_b: '',
    price: '0',
    againstPrice: '0',
    totalSupply: 0,
    shareOfPool: 0,
    uTokenBalance: '0',
    contractAgainstTokenBalance: '0',
    contractTokenBalance: '0'
  })
  const { pairs } = usePools()

  const pairConfig = useMemo(() => {
    return pairs.find((p) => p.contract_address === contractAddress)
  }, [contractAddress, pairs])

  const updatePair = useCallback(async () => {
    if (adapter && contractAddress && pairConfig) {
      const [ulPrice, tSupply, uPairAgainstTokenBalance] = await Promise.all([
        execute(contractAddress, ContractMethod.GET_PRICE),
        execute(contractAddress, ContractMethod.TOTAL_SUPPLY),
        adapter
          .getBalanceOf(pairConfig.token_b_address, contractAddress)
          .then((x) => x.balance)
      ])

      const price = BN(ulPrice.value)
        .dividedBy(getPrecision(pairConfig.token_b))
        .decimalPlaces(getDecimals(pairConfig.token_a))
        .toFixed()

      const uTokenBalance = getBalanceByToken(contractAddress)

      const totalSupply = BN(tSupply.value)
        .dividedBy(getPrecision(contractAddress))
        .toNumber()

      const contractAgainstTokenBalance = BN(uTokenBalance)
        .multipliedBy(uPairAgainstTokenBalance)
        .dividedBy(totalSupply)
        .toFixed()

      const contractTokenBalance = BN(contractAgainstTokenBalance)
        .dividedBy(price)
        .toFixed()

      const shareOfPool =
        BN(uTokenBalance).dividedBy(totalSupply).multipliedBy(100).toNumber() ||
        0

      const againstPrice = BN(1)
        .dividedBy(price)
        .decimalPlaces(getDecimals(pairConfig.token_b))
        .toFixed()

      setPairDetail((detail) => ({
        ...detail,
        price: isNaN(price) ? '0' : price,
        againstPrice: isNaN(againstPrice) ? '0' : againstPrice,
        totalSupply,
        uTokenBalance,
        contractTokenBalance: isNaN(contractTokenBalance)
          ? '0'
          : contractTokenBalance,
        contractAgainstTokenBalance: isNaN(contractAgainstTokenBalance)
          ? '0'
          : contractAgainstTokenBalance,
        shareOfPool,
        ...pairConfig
      }))
    }
    // eslint-disable-next-line
  }, [execute, contractAddress, adapter, pairConfig])

  const tokenA = useMemo(() => {
    if (pairConfig) {
      return getBalanceByToken(pairConfig.token_a_address)
    }
    return '0'
  }, [getBalanceByToken, pairConfig])

  const tokenB = useMemo(() => {
    if (pairConfig) {
      return getBalanceByToken(pairConfig.token_b_address)
    }
    return '0'
  }, [getBalanceByToken, pairConfig])

  const onClickApprove = async () => {
    if (
      adapter &&
      !isLoading &&
      isNumberAndNonZero(tokenAInput) &&
      pairConfig
    ) {
      if (
        BN(tokenAInput).isGreaterThan(tokenA) ||
        BN(tokenBInput).isGreaterThan(tokenB)
      ) {
        return addToast(<div>{t('amount-greater-than-balances')}</div>, {
          appearance: 'warning'
        })
      }

      if (!['ethereum'].includes(Config.blockchain)) {
        const allowanceNeedTokenA = BN(tokenAInput)
          .multipliedBy(getPrecision(pairConfig.token_a_address))
          .decimalPlaces(getDecimals(pairConfig.token_a_address))
          .toFixed()
        const allowanceNeedTokenB = BN(tokenBInput)
          .multipliedBy(getPrecision(pairConfig.token_b_address))
          .decimalPlaces(getDecimals(pairConfig.token_b_address))
          .toFixed()

        const currentAllowanceTokenA = await getAllowance(
          pairConfig.token_a_address
        )
        const currentAllowanceTokenB = await getAllowance(
          pairConfig.token_b_address
        )

        let approvals: Promise<any>[] = []

        if (BN(allowanceNeedTokenA).isGreaterThan(currentAllowanceTokenA)) {
          approvals.push(
            approveAllowance(allowanceNeedTokenA, pairConfig.token_a_address)
          )
        }
        if (BN(allowanceNeedTokenB).isGreaterThan(currentAllowanceTokenB)) {
          approvals.push(
            approveAllowance(allowanceNeedTokenB, pairConfig.token_b_address)
          )
        }

        await Promise.all(approvals)
      }

      const depositAmount = BN(tokenBInput)
        .multipliedBy(getPrecision(pairConfig!.token_b_address))
        .decimalPlaces(getDecimals(pairConfig!.token_b_address))
        .toNumber()

      const depositResult = await deposit(depositAmount, pairDetail.price)

      if (depositResult && depositResult.success) {
        addExpectedTransaction({
          transaction: depositResult,
          movements: {
            deposited: [
              {
                name: pairConfig.token_a,
                value: tokenAInput
              },
              {
                name: pairConfig.token_b,
                value: tokenBInput
              }
            ]
          }
        })

        history.push(
          `/liquidity/pool/join/${contractAddress}/success/${depositResult.hash}`
        )
      } else {
        history.push('/failed')
      }
    }
  }

  const onInputChange = (inputName: 'TokenA' | 'TokenB') => (value: string) => {
    if (isNaN(value) && value !== '') return
    const val = truncateDecimals(value || '0')
    if (inputName === 'TokenA') {
      const againstPrice = BN(val)
        .multipliedBy(pairDetail.price)
        .decimalPlaces(getDecimals(contractAddress))
        .toFixed()
      setTokenBInput(isNaN(againstPrice) ? '' : againstPrice)
      setTokenAInput(truncateDecimals(val))
    } else {
      const againstPrice = BN(val)
        .multipliedBy(pairDetail.againstPrice)
        .decimalPlaces(getDecimals(contractAddress))
        .toFixed()
      setTokenAInput(isNaN(againstPrice) ? '' : againstPrice)
      setTokenBInput(val)
    }
  }

  useEffect(() => {
    if (adapter) {
      updatePair()
    }
    // eslint-disable-next-line
  }, [adapter])

  useInterval(() => {
    updatePair()
  }, 4000)

  return (
    <div className="PoolJoin">
      <div className="PoolJoin__band PoolJoin__band-summary">
        <Table>
          <TableHeader
            columns={[
              t('liquidity.pool-join.AperB', {
                tokenA: pairDetail.token_a,
                tokenB: pairDetail.token_b
              }),
              t('liquidity.pool-join.shareOfPool'),
              t('liquidity.pool-join.BperA', {
                tokenA: pairDetail.token_a,
                tokenB: pairDetail.token_b
              })
            ]}
          />
          <TableRow
            columns={[
              <span>{toBNFixed(pairDetail.againstPrice)}</span>,
              <span className="color-dark-green">
                {localiseNumber(pairDetail.shareOfPool)} %
              </span>,
              <span>{toBNFixed(pairDetail.price)}</span>
            ]}
          />
        </Table>
      </div>
      <div className="PoolJoin__swap-options">
        <div className="PoolJoin__swap-options__pair">
          <div className="PoolJoin__swap-options__pair__header">
            <h1>{t('liquidity.pool-join.input')}</h1>
            <Button green icon={pairDetail.token_a}>
              {pairDetail.token_a}
            </Button>
          </div>
          <Input
            placeholder={t('liquidity.pool-join.input-amount-deposit')}
            balance={tokenA}
            value={tokenAInput}
            onChange={onInputChange('TokenA')}
            max
          />
        </div>
        <div className="PoolJoin__swap-options__add">
          <Add />
        </div>
        <div className="PoolJoin__swap-options__pair">
          <div className="PoolJoin__swap-options__pair__header">
            <h1>{t('liquidity.pool-join.input')}</h1>
            <Button green icon={pairDetail.token_b}>
              {pairDetail.token_b}
            </Button>
          </div>
          <Input
            placeholder={t('liquidity.pool-join.input-amount-deposit')}
            balance={tokenB}
            value={tokenBInput}
            onChange={onInputChange('TokenB')}
            max
          />
        </div>
      </div>
      <div className="PoolJoin__band PoolJoin__band-liquidity">
        <div>{t('liquidity.pool-join.user-liquidity')}</div>
        <Table>
          <TableRow
            columns={[
              <span>{pairDetail.name}</span>,
              <div className="text-align-right">{pairDetail.uTokenBalance}</div>
            ]}
          />
          <TableRow
            columns={[
              <span>{pairDetail.token_a}</span>,
              <div className="text-align-right">
                {localiseNumber(pairDetail.contractTokenBalance)}
              </div>
            ]}
          />
          <TableRow
            columns={[
              <span>{pairDetail.token_b}</span>,
              <div className="text-align-right">
                {localiseNumber(pairDetail.contractAgainstTokenBalance)}
              </div>
            ]}
          />
        </Table>
      </div>

      {pairConfig && (
        <div className="Wrapper-Bottom-Button">
          <Back />
          <PoolJoinButton
            pair={pairConfig}
            amountTokenA={tokenAInput}
            onClick={onClickApprove}
          />
        </div>
      )}
    </div>
  )
}

export { PoolJoin }
