import BlockchainConfigs from './unifi-config'

const BLOCKCHAIN_TARGET =
  (process.env.REACT_APP_BLOCKCHAIN as keyof typeof BlockchainConfigs) || 'tron'

const BlockchainConfig = BlockchainConfigs[BLOCKCHAIN_TARGET]

const Config = { ...BlockchainConfig, numberLocale: 'en-US' }

export { Config }
