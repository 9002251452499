import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { NON_SUCCESS_RESPONSE } from 'Adapters/IAdapter'
import { ContractMethod } from 'Adapters/Contract'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { Config } from '../../Config'
import TronLinkAdapter from '../../Adapters/TronLinkAdapter'
import OntologyAdapter from '../../Adapters/OntologyAdapter'
import HarmonyAdapter from '../../Adapters/HarmonyAdapter'
import IconAdapter from '../../Adapters/IconAdapter'
import BSCAdapter from '../../Adapters/BSCAdapter'
import EthAdapter from '../../Adapters/EthAdapter'
import { Pair, Contract, Farm } from '../Pools/Types'
import { Adapter } from './Adapter'
import { useLoading } from '../Loading/useLoading'
import IotexAdapter from '../../Adapters/IotexAdapter'

const AdapterFactory: {
  [key: string]: () => Promise<{
    default:
      | typeof TronLinkAdapter
      | typeof OntologyAdapter
      | typeof HarmonyAdapter
      | typeof IconAdapter
      | typeof BSCAdapter
      | typeof EthAdapter
      | typeof IotexAdapter
  }>
} = {
  tron: () => import('../../Adapters/TronLinkAdapter'),
  ontology: () => import('../../Adapters/OntologyAdapter'),
  harmony: () => import('../../Adapters/HarmonyAdapter'),
  icon: () => import('../../Adapters/IconAdapter'),
  binance: () => import('../../Adapters/BSCAdapter'),
  ethereum: () => import('../../Adapters/EthAdapter'),
  iotex: () => import('../../Adapters/IotexAdapter')
}

export const useAdapter = () => {
  const [{ adapter, isConnected }, setAdapter] = useRecoilState(Adapter)
  const { addLoad, finishLoad } = useLoading()
  const { addTransaction } = useTransactions()

  const connect = async (props: {
    pairs: Pair[]
    contracts: { [token: string]: Contract }
    farms: Farm[]
  }) => {
    const AdapterBuilder = await AdapterFactory[
      Config.blockchain as keyof typeof AdapterFactory
    ]().then(({ default: adpt }) => {
      return adpt
    })

    const adapterInstance = new AdapterBuilder()
    await adapterInstance.connect(props)

    setAdapter({
      adapter: adapterInstance,
      isConnected: adapterInstance.isConnected()
    })
  }

  const execute = useCallback(
    async (
      contractName: string,
      method: ContractMethod,
      values: {
        args?: Array<string | number | undefined>
        callValue?: string | number | undefined
      } = {},
      isWrite = false
    ) => {
      try {
        addLoad()
        if (adapter) {
          const reducedValues = {
            args: [],
            callValue: 0,
            ...values
          }
          const res = await adapter.execute(
            contractName,
            method,
            reducedValues,
            isWrite
          )

          addTransaction(res)
          return res
        }
        return { ...NON_SUCCESS_RESPONSE, functionName: method, params: values }
      } catch (err) {
        console.error('useAdapter -> err', err)
        return { ...NON_SUCCESS_RESPONSE, functionName: method, params: values }
      } finally {
        finishLoad()
      }
    },
    [addTransaction, addLoad, finishLoad, adapter]
  )

  return {
    connect,
    execute,
    adapter,
    isConnected
  }
}
