import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Barn from 'Assets/Icons/Barn.png'
import { usePools } from '../../Stores/Pools/usePools'
import { FarmPool } from './Farm'
import { useBalances } from '../../Stores/Balances/useBalances'
import { Farm } from '../../Stores/Pools/Types'
import { BN } from '../../Utils/BigNumber'
import { CheckboxSelector } from '../../Components/CheckboxSelector'
import Select, { Styles } from 'react-select'

import './Farms.scss'

const selectorStyles: Styles<any, any> = {
  control: (provided, state) => ({
    ...provided,
    width: '10rem',
    borderColor: state.isFocused ? '#3df997' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #3df997' : 'none',
    ':hover': {
      borderColor: '#3df997'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3df997' : 'white',
    color: state.isSelected ? '#000' : '#000',
    ':hover': {
      backgroundColor: state.isSelected ? '#3df997' : '#d1fee7'
    }
  })
}

const selectorOptions = [
  { value: 'bonus', label: 'Bonus Farm' },
  { value: 'daily-apr', label: 'Daily APR' },
  { value: 'apr', label: 'APR' },
  { value: 'liquidity', label: 'Liquidity' },
  { value: 'finished', label: 'Finished' }
]

export const Farms = () => {
  const [stakedOnly, setStakedOnly] = useState(false)
  const [sortOption, setSortOption] = useState<typeof selectorOptions[number]>(
    selectorOptions[0]
  )
  const { t } = useTranslation()
  const { farms } = usePools()
  const { getBalanceByToken } = useBalances()

  const reducedFarms = useMemo(() => {
    let farmsResult = [...farms]

    if (stakedOnly) {
      farmsResult = farms.reduce((farms: Farm[], farm) => {
        const uFarmBalance = getBalanceByToken(farm.contract_address)
        if (BN(uFarmBalance).isGreaterThan(0)) {
          return [farm, ...farms]
        }
        return farms
      }, [])
    }

    sortFarmsByOption(sortOption, farmsResult)

    return farmsResult
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms, stakedOnly, sortOption])

  return (
    <div className="Farms">
      <div className="Farms__header">
        <img src={Barn} alt="Farm" />
        <span>
          <h1>{t('farms.title')}</h1>
          <ul>
            <li>{t('farms.p-1')}</li>
            <li>{t('farms.p-2')}</li>
            <li>{t('farms.p-3')}</li>
            <li>
              <Trans i18nKey="farms.p-4">
                For more information press{' '}
                <a
                  href="https://unifiprotocol.zendesk.com/hc/en-us/articles/360057429332"
                  title="more information"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </Trans>
            </li>
          </ul>
          <p>
            <Trans i18nKey="farms.p-5">
              For more information press{' '}
              <a
                href="https://medium.com/unifiprotocol/unifi-farms-on-multiple-blockchains-7373048e3c0e"
                title="more information"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Trans>
          </p>
        </span>
      </div>
      <div className="Farms__options">
        <span className="Farms__options__sort">
          <span className="Farms__options__sort__title">
            {t('farms.controls.sort-by')}
          </span>
          <Select
            value={sortOption}
            styles={selectorStyles}
            placeholder="Sort by"
            options={selectorOptions}
            onChange={setSortOption as any}
          />
        </span>
        <span>
          <CheckboxSelector
            active={stakedOnly}
            onToggle={() => setTimeout(() => setStakedOnly((s) => !s))}
          >
            {t('farms.controls.staked-only')}
          </CheckboxSelector>
        </span>
      </div>
      <div className="Farms__content">
        {reducedFarms.map((farm, i) => (
          <FarmPool farm={farm} key={i} />
        ))}
      </div>
    </div>
  )
}

function sortFarmsByOption(
  option: typeof selectorOptions[number],
  farms: Farm[]
) {
  const options: {
    [K in typeof selectorOptions[number]['value']]: () => void
  } = {
    bonus: () => {
      farms.sort((a, b) => {
        if (Number(b.is_bonus) > Number(a.is_bonus)) return 1
        if (Number(b.is_bonus) < Number(a.is_bonus)) return -1
        return (
          Number(b.liquidity.annual_percentage_yearly) -
          Number(a.liquidity.annual_percentage_yearly)
        )
      })
    },
    'daily-apr': () => {
      farms.sort(
        (a, b) =>
          Number(b.liquidity.variable_annual_percentage_rate) -
          Number(a.liquidity.variable_annual_percentage_rate)
      )
    },
    apr: () => {
      farms.sort(
        (a, b) =>
          Number(b.liquidity.annual_percentage_yearly) -
          Number(a.liquidity.annual_percentage_yearly)
      )
    },
    liquidity: () => {
      farms.sort(
        (a, b) => Number(b.liquidity.total_usd) - Number(a.liquidity.total_usd)
      )
    },
    finished: () => {
      farms.sort((a, b) => {
        if (Number(b.is_finished) > Number(a.is_finished)) return 1
        if (Number(b.is_finished) < Number(a.is_finished)) return -1
        return (
          Number(b.liquidity.annual_percentage_yearly) -
          Number(a.liquidity.annual_percentage_yearly)
        )
      })
    }
  }
  options[option.value]()
  return farms
}
