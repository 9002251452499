import React from 'react'

import './CheckboxSelector.scss'

export const CheckboxSelector: React.FC<{
  active: boolean
  onToggle: () => void
}> = ({ onToggle, active, children }) => {
  return (
    <span className="CheckboxSelector">
      <span
        onClick={onToggle}
        className={`CheckboxSelector__checkbox ${
          active ? 'active' : 'no-active'
        }`}
      >
        <span className="CheckboxSelector__checkbox__ball"></span>
      </span>
      <span className="CheckboxSelector__label">{children}</span>
    </span>
  )
}
