import { Config } from 'Config'
import { getPoolsRoute } from '../Stores/API/useAPI'
import { Pair } from '../Stores/Pools/Types'
import { fetchJSON } from './Fetch'

const PAIRS_ENDPOINT = getPoolsRoute('pairs')

const powerDictionary: {
  [key: string]: { precision: number; decimals: number }
} = {}

;(() => {
  fetchJSON<{ pairs: Pair[] }>(PAIRS_ENDPOINT).then(({ pairs }) => {
    pairs.forEach((p) => {
      powerDictionary[p.contract_address] = {
        decimals: p.decimals,
        precision: Math.pow(10, p.decimals)
      }
      powerDictionary[p.token_a] = {
        decimals: p.token_a_decimals,
        precision: Math.pow(10, p.token_a_decimals)
      }
      powerDictionary[p.token_b] = {
        decimals: p.token_b_decimals,
        precision: Math.pow(10, p.token_b_decimals)
      }
      powerDictionary[p.token_a_address] = {
        decimals: p.token_a_decimals,
        precision: Math.pow(10, p.token_a_decimals)
      }
      powerDictionary[p.token_b_address] = {
        decimals: p.token_b_decimals,
        precision: Math.pow(10, p.token_b_decimals)
      }
    })
  })
})()

export const getPrecision = (tokenOrContract: string) => {
  return powerDictionary[tokenOrContract]
    ? powerDictionary[tokenOrContract]['precision']
    : Config.globalPrecision
}

export const getDecimals = (tokenOrContract: string) => {
  return powerDictionary[tokenOrContract]
    ? powerDictionary[tokenOrContract]['decimals']
    : Config.globalPowerPrecision
}
