import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Input from 'Components/Input'
import { useBalances } from 'Stores/Balances/useBalances'
import BottomButton from 'Components/BottomButton'
import {
  isNumberAndNonZero,
  truncateDecimals,
  isNaN,
  BN
} from 'Utils/BigNumber'
import BigNumber from 'bignumber.js'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useLoading } from 'Stores/Loading/useLoading'
import { useTransactions } from 'Stores/Transactions/useTransactions'
import { useTranslation } from 'react-i18next'
import { isEthAddress } from '../../Utils/ETH'
import { useSeedMigrate } from '../../Stores/Adapter/useSeedMigrate'
import { Config } from '../../Config'
import { useAdapter } from '../../Stores/Adapter/useAdapter'
import Button from '../../Components/Button'

import './DepositSeed.scss'

const SEED: { address: string; decimals: number } = (Config.contracts as any)
  .SEED

const DepositSeed: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { addToast } = useToasts()
  const { getBalanceByToken, balances } = useBalances()
  const {
    getCurrentDeposit,
    migrate,
    getClaimScore,
    claimMigrationUP,
    getScoring
  } = useSeedMigrate()
  const { isLoading } = useLoading()
  const [migrateAmount, setMigrateAmount] = useState('')
  const [migrateAddress, setMigrateAddress] = useState('')
  const { addExpectedTransaction } = useTransactions()
  const { adapter } = useAdapter()
  const [currentDeposited, setCurrentDeposited] =
    useState<{ seed: string; address: string }>()
  const [claimScore, setClaimScore] = useState('0')
  const [scoring, setScoring] = useState('0')

  const seedBalance = useMemo(
    () => getBalanceByToken(SEED.address),
    // eslint-disable-next-line
    [balances, getBalanceByToken]
  )

  const onClickApprove = async () => {
    if (isNumberAndNonZero(migrateAmount) && !isLoading) {
      if (new BigNumber(migrateAmount).isGreaterThan(seedBalance)) {
        return addToast(<div>{t('up.invalid-amount')}</div>, {
          appearance: 'warning'
        })
      }

      if (!isEthAddress(migrateAddress)) {
        return addToast(<div>{t('migrate-up.invalid-address')}</div>, {
          appearance: 'warning'
        })
      }

      const migrateResponse = await migrate(migrateAmount, migrateAddress)

      addExpectedTransaction({
        transaction: migrateResponse,
        movements: {
          deposited: [{ name: 'SEED', value: migrateAmount }]
        }
      })

      if (migrateResponse.success) {
        history.push(`/seed/success/${migrateResponse.hash}`)
      } else {
        history.push('/failed')
      }
    }
  }

  const onClaimYouUP = useCallback(() => {
    if (BN(claimScore).isGreaterThan(0)) {
      claimMigrationUP()
        .then((res) => {
          if (res.success) {
            addToast(<div>Your UP has been claimed successfully</div>, {
              appearance: 'success'
            })
          } else {
            addToast(<div>An error claiming your UP happened</div>, {
              appearance: 'error'
            })
          }
          getClaimScore().then(setClaimScore)
        })
        .catch(() => {
          addToast(<div>An error claiming your UP happened</div>, {
            appearance: 'error'
          })
        })
    }
  }, [addToast, claimMigrationUP, claimScore, getClaimScore])

  const onInputChange = async (val: string) => {
    if (isNaN(val) && val !== '') return
    const value = val || '0'
    setMigrateAmount(truncateDecimals(value))
  }

  useEffect(() => {
    if (!adapter || currentDeposited) return
    getCurrentDeposit().then(setCurrentDeposited)
    getClaimScore().then(setClaimScore)
    getScoring().then(setScoring)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapter])

  return (
    <div className="DepositSeed">
      <div className="DepositSeed__header">
        <img src="https://assets.unifiprotocol.com/SEED.png" alt="SEED" />
        <span>
          <h1>SEED to UNFI migration</h1>
          <p>
            For a limited time, SEED holders can migrate their SEED. UNFI tokens
            from SEED’s redeem value will be distributed at a later date on BSC
            to the address provided by the participant. All migrated SEED will
            receive the same amount of UNFI per SEED.
          </p>
          <p>
            Click{' '}
            <a
              href="https://docs.google.com/document/d/1GnzV6ccdxEqk7kzs2ol7rHgt4-YSrnIo1zrzOUUNZi4/edit#heading=h.vrmw214ejedg"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            for more information
          </p>
        </span>
      </div>
      {adapter && (
        <div className="DepositSeed__deposited">
          {currentDeposited && !BN(currentDeposited.seed).isZero() && (
            <p>
              You have already deposited <b>{currentDeposited.seed}</b> SEED
              with the BSC address <b>{currentDeposited.address}</b> as the
              target of the claim.
            </p>
          )}
          <p>
            <p>Your available SEED Snapshot claim is {scoring}.</p>
            <Button
              onClick={onClaimYouUP}
              disabled={!BN(claimScore).isGreaterThan(0)}
            >
              Claim your UP
            </Button>
          </p>
        </div>
      )}
      <div className="DepositSeed__redeem">
        <Input
          placeholder={t('migrate-up.enter-amount')}
          balance={seedBalance}
          onChange={onInputChange}
          value={migrateAmount}
          max
          exactMax
        />
        <Input
          placeholder={'Enter a valid BSC address'}
          onChange={setMigrateAddress}
          value={migrateAddress}
        />
      </div>
      <div className="Wrapper-Bottom-Button">
        <BottomButton label={t('up.submit')} onClick={onClickApprove} />
      </div>
    </div>
  )
}

export { DepositSeed }
