import React, { useEffect, useState } from 'react'
import { BlockchainSelector } from '../BlockchainSelector'
import { DocumentationDropdown } from '../DocumentationDropdown'
import { SettingsDropdown } from '../SettingsDropdown'
import { ReactComponent as Leaderboard } from 'Assets/Icons/Leaderboard.svg'
import { TransactionsDropdown } from '../TransactionsDropdown'
import { useTranslation } from 'react-i18next'

import './TopHeader.scss'

const TopHeader: React.FC = () => {
  const [hide, setHide] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    function scrollHandler() {
      if (window.pageYOffset > 100) {
        setHide(true)
      } else {
        setHide(false)
      }
    }
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <div className={`TopHeader hide-for-mobile ${hide ? 'hide' : 'no-hide'}`}>
      <span>
        <BlockchainSelector />
      </span>
      <span>
        <TransactionsDropdown />
        <DocumentationDropdown />
        <span className="menu-item">
          <a
            href="https://unifi.report"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Leaderboard />
            <span>{t('top-header.stats')}</span>
          </a>
        </span>
        <SettingsDropdown />
      </span>
    </div>
  )
}

export default TopHeader
