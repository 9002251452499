import EventEmitter from 'eventemitter3'

const eventEmitter = new EventEmitter<EmitterAction, any>()

const Emitter = {
  on: (event: EmitterAction, fn: (payload?: any) => void) =>
    eventEmitter.on(event, fn),
  once: (event: EmitterAction, fn: (...args: any[]) => void) =>
    eventEmitter.once(event, fn),
  off: (event: EmitterAction, fn: (...args: any[]) => void) =>
    eventEmitter.off(event, fn),
  emit: (event: EmitterAction, payload?: any) =>
    eventEmitter.emit(event, payload)
}

Object.freeze(Emitter)

enum EmitterAction {
  REFRESH_BALANCES = 'REFRESH_BALANCES',
  BALANCE = 'BALANCE',
  TRANSACTION_CONFIRMED = 'TRANSACTION_CONFIRMED',
  MESSAGE = 'MESSAGE'
}

export { Emitter, EmitterAction }
