import { Settings } from '@material-ui/icons'
import i18next from 'i18next'
import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useRecoilState } from 'recoil'
import { Config } from '../../Config'
import { useAdapter } from '../../Stores/Adapter/useAdapter'
import { Parameters } from '../../Stores/Parameters/Parameters'
import { usePools } from '../../Stores/Pools/usePools'
import { useDetectOutsideClick } from '../../Utils/useDetectOutsideClick'

import './SettingsDropdown.scss'

export const SettingsDropdown = () => {
  const dropdownRef = useRef(null)
  const { isActive, setIsActive } = useDetectOutsideClick(dropdownRef, false)
  const onClick = () => setIsActive(!isActive)
  const { pools } = usePools()
  const { adapter } = useAdapter()
  const [{ unlimitedApproval }, setAppParameters] = useRecoilState(Parameters)
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const hasDerivatives = useMemo(() => {
    return (
      pools.filter((p) => p.isDerivative && p.isDerivative === true).length > 0
    )
  }, [pools])

  const logoutEnabled = useMemo(() => {
    return ['harmony'].includes(Config.blockchain)
  }, [])

  const onApprovalChange = useCallback(() => {
    setAppParameters((state) => ({
      ...state,
      unlimitedApproval: !state.unlimitedApproval
    }))

    if (!unlimitedApproval) {
      addToast(
        <div>
          <div>{t('settings.unlimited-approval-message.p-1')}</div>
          <div>{t('settings.unlimited-approval-message.p-2')}</div>
          <div>{t('settings.unlimited-approval-message.p-3')}</div>
        </div>,
        {
          appearance: 'warning'
        }
      )
    }
  }, [setAppParameters, addToast, t, unlimitedApproval])

  const onLanguageChange = (isoLang: string) => {
    i18next.changeLanguage(isoLang)
  }

  return (
    <div className="SettingsDropdown">
      <span onClick={onClick} className="SettingsDropdown__trigger">
        <Settings />
      </span>
      <nav
        ref={dropdownRef}
        className={`SettingsDropdown__menu ${isActive ? 'active' : 'inactive'}`}
      >
        <ul>
          {hasDerivatives && (
            <li className="bordered">
              <a href="/derivatives">{t('settings.derivatives-market')}</a>
            </li>
          )}
          {['ethereum', 'binance', 'tron', 'harmony'].includes(
            Config.blockchain
          ) && (
            <>
              <li className="header">
                {t('settings.settings-submenu-header')}
              </li>
              <li>
                <input
                  id="unlimited-approval"
                  type="checkbox"
                  checked={unlimitedApproval}
                  onChange={onApprovalChange}
                />
                <label htmlFor="unlimited-approval">
                  {t('settings.unlimited-approval')}
                </label>
              </li>
            </>
          )}
          <li className="header"> {t('settings.language')}</li>
          <li onClick={() => onLanguageChange('en')}>English</li>
          <li onClick={() => onLanguageChange('es')}>Spanish</li>
          <li onClick={() => onLanguageChange('cn')}>Chinese</li>
          <li className="bordered" onClick={() => onLanguageChange('kr')}>
            Korean
          </li>
          {adapter && logoutEnabled && (
            <li
              className="color-red"
              onClick={() => {
                adapter.logout()
              }}
            >
              {t('settings.logout')}
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}
