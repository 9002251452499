import { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { Loading } from './Loading'

export const useLoading = () => {
  const [{ loading, totalRequests }, setLoadingState] = useRecoilState(Loading)

  const isLoading = useMemo(() => {
    return loading !== totalRequests
  }, [loading, totalRequests])

  const addLoad = () =>
    setLoadingState((state) => ({ ...state, loading: state.loading + 1 }))

  const finishLoad = () =>
    setLoadingState((state) => ({
      ...state,
      totalRequests: state.totalRequests + 1
    }))

  return {
    addLoad,
    finishLoad,
    loading,
    totalRequests,
    isLoading
  }
}
