import React, { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../Button'
import { useAdapter } from 'Stores/Adapter/useAdapter'
import { ReactComponent as UnifiLogo } from 'Assets/unifi-logo.svg'
import { useToasts } from 'react-toast-notifications'
import { Config } from 'Config'
import { useTranslation } from 'react-i18next'
import { usePools } from '../../Stores/Pools/usePools'

import './Header.scss'

const BlockchainAddon: { [key: string]: string } = {
  harmony: 'OneWallet',
  ontology: 'CyanoWallet',
  tron: 'TronLink',
  icon: 'ICONex',
  ethereum: 'MetaMask',
  binance: 'Binance Chain Wallet',
  iotex: 'ioPay'
}

const Header = () => {
  const history = useHistory()
  const location = useLocation()
  const { connect, adapter, isConnected } = useAdapter()
  const { contracts, pairs, farms } = usePools()
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const formattedAddress = useMemo(() => {
    return isConnected && adapter?.getAddress()
      ? shortAddress(adapter.getAddress())
      : ''
  }, [adapter, isConnected])

  const addressExplorerLink = useMemo(() => {
    if (adapter && isConnected) {
      return Config.accountExplorer.replace('{{ADDRESS}}', adapter.getAddress())
    }
    return ''
  }, [adapter, isConnected])

  const onClickConnectionButton = useCallback(() => {
    if (!isConnected) {
      connect({ contracts, pairs, farms })
      addToast(
        <div>
          {t('header.wallet-toast', {
            wallet: BlockchainAddon[Config.blockchain]
          })}
        </div>,
        {
          appearance: 'info'
        }
      )
    }
  }, [addToast, t, connect, contracts, pairs, farms, isConnected])

  return (
    <div className="Header">
      <span className="Header__logo" onClick={() => history.push('/')}>
        <UnifiLogo />
      </span>
      <span className="Header__nav">
        <div
          className={location.pathname.match(/^\/$/) ? 'active' : 'no-active'}
        >
          <Button white onClick={() => history.push('/')} icon="SwapCalls">
            {t('header.section.trade')}
          </Button>
        </div>
        <div
          className={
            location.pathname.match(/^\/liquidity/) ? 'active' : 'no-active'
          }
        >
          <Button
            white
            onClick={() => history.push('/liquidity')}
            icon="Plumber"
          >
            {t('header.section.liquidity')}
          </Button>
        </div>
        <div
          className={
            location.pathname.match(/^\/farms/) ? 'active' : 'no-active'
          }
        >
          <Button
            white
            onClick={() => history.push('/farms')}
            icon="Agriculture"
          >
            {t('header.section.farms')}
          </Button>
        </div>
        <div
          className={location.pathname.match(/^\/up/) ? 'active' : 'no-active'}
        >
          <Button white onClick={() => history.push('/up')} icon="Toll">
            {t('header.section.up')}
          </Button>
        </div>
      </span>
      <div className="Header__connection">
        <Button green={isConnected} onClick={onClickConnectionButton}>
          {isConnected ? (
            <a
              className="anchor-wallet"
              href={addressExplorerLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formattedAddress}
            </a>
          ) : (
            t('header.wallet')
          )}
        </Button>
      </div>
    </div>
  )
}

function shortAddress(address: string) {
  const firstLetters = address.substr(0, 6)
  const endingLetters = address.substr(-6, 6)
  return firstLetters + '...' + endingLetters
}

export default Header
